import React, { useState, useEffect, lazy, Suspense } from "react";
// import FigurineCard from "./FigurineCard";

import FilterButtons from "./FilterButtons";
import function_cards_sort from "../utils/function_cards_sort";
import { isDateGreaterThanToday } from '../utils/function_isDateGreaterThanToday';
import ScrollToTopButton from "../utils/ScrollToTopButton";
import config_json from '../utils/config_json';

const FigurineCard = lazy(() => import('./FigurineCard'));


function FigurineCardsList(props) {
  const [filteredFigurinesInit, setFilteredFigurinesInit] = useState([]);
  const [filteredFigurines, setFilteredFigurines] = useState([]);
  const [filteredFigurinesFuture, setFilteredFigurinesFuture] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [itemCountFuture, setItemCountFuture] = useState(0);
  const [addCount, setAddCount] = useState(0);
  const [favCount, setFavCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedValue, setSelectedValue] = useState("");
  const [edSpeList, setEdSpeList] = useState("");


  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(false);

  // défini la valeur de recherche des nouveautés sorties dans les X derniers mois
  const period = 4


  useEffect(() => {
    fetch(config_json.jsonFigurinesURL)
      .then(response => response.json())
      .then(data => {

        // Tri des données par licence (par défaut)
        const filteredDataByLicence = data.filter(item => item.slug_licence === props.slugLicence);



        /////  Tri des données pour la page "Nouveautés"

        //  Tri pour les élements sortis dans les x derniers mois (x = const period)
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const xMonthsAgo = new Date(today);
        xMonthsAgo.setMonth(today.getMonth() - period);

        const recentLicenceNames = filteredDataByLicence.filter(item => {
          const itemDate = new Date(item.date_sortie_tri);

          // Exclure le mois actuel des x derniers mois
          const isCurrentMonth = itemDate.getMonth() === today.getMonth() && itemDate.getFullYear() === today.getFullYear();
          const isWithinXMonths = itemDate >= xMonthsAgo && itemDate < today;

          return isWithinXMonths && !isCurrentMonth;
        });

        // - Tri pour les élements à venir (date sup à aujourd'hui)
        const futureLicenceNames = filteredDataByLicence.filter(item => {
          const itemDate = new Date(item.date_sortie_tri);
          const itemMonth = itemDate.getMonth() + 1; // Récupérer le mois (1-12, ajouter 1 pour avoir les mois de 1 à 12)
          const currentMonth = today.getMonth() + 1; // Récupérer le mois actuel (1-12)

          // Si le mois de l'élément est supérieur ou égal au mois actuel, il est considéré comme futur
          if (itemDate.getFullYear() > today.getFullYear() || (itemDate.getFullYear() === today.getFullYear() && itemMonth >= currentMonth)) {
            return true;
          }
          return false;
        });




        let sortedData
        let sortedDataFutures

        if (props.categoryName === "Nouveautés") {
          // Si c'est la page Nouveautés...
          sortedData = function_cards_sort(recentLicenceNames);
          sortedDataFutures = function_cards_sort(futureLicenceNames);
        } else {
          // Si c'est la page Licence...

          // Tri complèxe par ref et par date de sortie
          // sortedData = function_cards_sort(filteredDataByLicence);

          // Tri simplifié par ref uniquement
          sortedData = filteredDataByLicence.sort((a, b) => a.ref - b.ref);

        }


        //--------------  Filtre par Edition -----------------

        let filteredResults;

        if (selectedValue) {
          filteredResults = sortedData.filter((item) =>
            item.editions?.ed_spe1 === selectedValue.value ||
            item.editions?.ed_spe2 === selectedValue.value ||
            item.editions?.ed_spe3 === selectedValue.value ||
            item.variant_form === selectedValue.value
          );
        } else {
          filteredResults = sortedData;
        }


        // Trier les éléments filtrés en fonction de sortOrder (ordre arbitraire des éditions dans le menu déroulant)
        const sortedByEdSpe = sortOrder === "asc" ? filteredResults : [...filteredResults].reverse();


        setFilteredFigurines(sortedByEdSpe);
        setFilteredFigurinesInit(sortedData);
        setFilteredFigurinesFuture(sortedDataFutures);

        const sortedAdd = sortedByEdSpe.filter((item) => item.add_state === true);
        const sortedFav = sortedByEdSpe.filter((item) => item.fav_state === true);

        setItemCount(sortedByEdSpe.length);
        setAddCount(sortedAdd.length);
        setFavCount(sortedFav.length);

        if (props.categoryName === "Nouveautés") {
          // Si c'est la page Nouveautés...
          setItemCountFuture(sortedDataFutures.length);
        }



        //-------------------------------------

        setLoadingData(false);
        setError(false);
      })
      .catch(error => {
        // Afficher l'erreur dans la console
        console.log(error);

        // Mettre à jour les variables d'état en cas d'erreur
        setLoadingData(false);
        setError(true);
      })

    fetch(config_json.jsonEdSpeURL)
      .then(response => response.json())
      .then(data => {

        // Tri des données par licence (par défaut)
        const listOfEdSpe = data.map(item => item.edition)

        setEdSpeList(listOfEdSpe)

      })
      .catch(error => {
        // Afficher l'erreur dans la console
        console.log(error);

        // Mettre à jour les variables d'état en cas d'erreur
        setLoadingData(false);
        setError(true);
      })

  }, [props.slugLicence, sortOrder, selectedValue, props.categoryName]);




  // Créer un ensemble unique de toutes les valeurs de "ed_spe1", "ed_spe2" et "ed_spe3"
  const uniqueEdSpeValues = [
    ...new Set([
      ...filteredFigurinesInit.map(item => item.editions && item.editions.ed_spe1).filter(Boolean),
      ...filteredFigurinesInit.map(item => item.editions && item.editions.ed_spe2).filter(Boolean),
      ...filteredFigurinesInit.map(item => item.editions && item.editions.ed_spe3).filter(Boolean),
      ...filteredFigurinesInit.map(item => item.variant_form).filter(Boolean)
    ])
  ];



  // Triez les valeurs en utilisant l'ordre arbitraire de la BDD des editions speciales (pop_collection_bdd_ed_spe)
  const sortedEdSpeValues = uniqueEdSpeValues.sort((a, b) =>
    edSpeList.indexOf(a) - edSpeList.indexOf(b));


  const handleToggleSort = () => {
    if (sortOrder === "asc") {
      setSortOrder("desc")
    } else {
      setSortOrder("asc")
    }
  };



  // Filtre par Edition
  const handleSelect = (value) => {
    setSelectedValue(value);
  };


  // Contenu de la liste déroulante
  const options = uniqueEdSpeValues.map(option => ({
    value: option,
    label: option
  }));


  // Réinitialisation des filtres
  const handleResetFilters = () => {
    const sorted = sortOrder === "asc" ? filteredFigurinesInit : [...filteredFigurinesInit].reverse();
    setFilteredFigurines(sorted);

    const sortedAdd = sorted.filter((item) => item.add_state === true);
    const sortedFav = sorted.filter((item) => item.fav_state === true);
    setAddCount(sortedAdd.length);
    setFavCount(sortedFav.length);
    setItemCount(sorted.length);
    setSelectedValue("");
  };



  if (loadingData) {
    return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Chargement des figurines...</div >;
  }

  if (error) {
    return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Une erreur s'est produite lors du chargement des données. <br />
      Veuillez recharger la page.</div >;
  }
  return (
    <>
      {/* // Bloc infos + filtre par edition - ne s'affiche pas sur la page Nouveautés*/}
      {props.categoryName !== "Nouveautés" && (
        <FilterButtons
          handleSelect={handleSelect}
          handleResetFilters={handleResetFilters}
          itemCount={itemCount}
          addCount={addCount}
          favCount={favCount}
          slugLicence={props.slugLicence}
          licence={props.licence}
          sortedEdSpeValues={sortedEdSpeValues}
          selectedValue={selectedValue}
          options={options}
          categoryName={props.categoryName}
        />
      )}


      {/* // tri par ref - ne s'affiche pas sur la page Nouveautés*/}
      {props.categoryName !== "Nouveautés" && (
        <div div className="sort-by-date" title={sortOrder === "asc" ? "Trier les références par ordre décroissant" : "Trier les références par ordre croissant"} onClick={handleToggleSort}>
          <div className="color-mask"></div>
          <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-hashtag-square.svg" alt="" />
          {sortOrder === "asc" ? (
            <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-1-to-9.svg" alt="" />
          ) : (
            <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-9-to-1.svg" alt="" />)}
        </div >
      )}



      {/*  // "section Figurines "à venir" - Visible uniquement sur la page Nouveautés */}
      {props.categoryName === "Nouveautés" && itemCountFuture > 0 && (
        <>
          <div className="recent-figurines-title-container">
            <div className="future-figurines-title">Figurines à venir</div>
          </div>

          <div className={`cards-container card-grid-big ${itemCountFuture <= 4 ? 'uncomplete-row' : ''}`}>
            {/* Tri par ref puis par date */}
            {filteredFigurinesFuture.map((item) => (

              <Suspense fallback={
                <div>
                  <img
                    src="https://thomak.xyz/pop-collection/assets/images/figurineCard-placeholder.svg"
                    alt=""
                  />
                </div>
              }>
                <FigurineCard
                  key={item.id}
                  id={item.id}
                  id_funko={item.id_funko}
                  ref_funko={item.ref}

                  nom={item.nom}
                  nom_ed={item.nom_ed}
                  licence={item.licence}
                  couleur_licence={item.couleur_licence}

                  image={item.images.img_url1}
                  imageHover={item.images.img_url3}

                  editions={item.editions}
                  ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                  ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                  ed_spe3={item.editions ? item.editions.ed_spe3 : null}
                  variant_form={item.variant_form}

                  date_sortie={item.date_sortie_aff}

                  // debug
                  date_sortie_tri={item.date_sortie_tri}

                  fav_state={item.fav_state}
                  add_state={item.add_state}

                  slug_figurine={item.slug_figurine}
                  slug_licence={item.slug_licence}

                  future={isDateGreaterThanToday(item.date_sortie_tri)}
                  categoryName={props.categoryName}
                />
              </Suspense>


            ))}
          </div>
        </>
      )}



      {props.categoryName === "Nouveautés" && (
        <div className="recent-figurines-title-container">
          <div className="recent-figurines-title">Figurines sorties récemment</div>
        </div>
      )}


      <div className={`cards-container card-grid-big ${itemCount <= 4 ? 'uncomplete-row' : ''}`}>
        {/* Tri par ref puis par date */}
        {filteredFigurines.map((item) => (
          <Suspense fallback={
            <div>
              <img
                src="https://thomak.xyz/pop-collection/assets/images/figurineCard-placeholder.svg"
                alt=""
              />
            </div>
          }>

            <FigurineCard
              key={item.id}
              id={item.id}
              id_funko={item.id_funko}
              ref_funko={item.ref}

              nom={item.nom}
              nom_ed={item.nom_ed}
              licence={item.licence}
              couleur_licence={item.couleur_licence}

              image={item.images.img_url1}
              imageHover={item.images.img_url3}

              editions={item.editions}
              ed_spe1={item.editions ? item.editions.ed_spe1 : null}
              ed_spe2={item.editions ? item.editions.ed_spe2 : null}
              ed_spe3={item.editions ? item.editions.ed_spe3 : null}
              variant_form={item.variant_form}

              date_sortie={item.date_sortie_aff}

              // debug
              date_sortie_tri={item.date_sortie_tri}

              fav_state={item.fav_state}
              add_state={item.add_state}

              slug_figurine={item.slug_figurine}
              slug_licence={item.slug_licence}

              future={isDateGreaterThanToday(item.date_sortie_tri)}
              categoryName={props.categoryName}
            />
          </Suspense>
        ))}
      </div>

      <ScrollToTopButton />
    </>
  );
}

export default FigurineCardsList;