import React from 'react';
import { Link } from 'react-router-dom';

const ConnexionScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Connectez-vous <br />
                    <span>pour gérer votre collection de figurines Pop</span></div>
                <form>
                    <input type="email" placeholder="Adresse email" />
                    <input type="password" placeholder="Mot de passe" />
                </form>
                {/* TODO Ajouter le lien */}
                <Link onClick={() => handleScreenChange('reinitPassword')} >Mot de passe oublié ?</Link>
                <div className="button button_primary" onClick={() => handleScreenChange('user')}  >Connexion</div>
                <span>ou</span>
                <div className="row">
                    <div className="sso-bt bt-google"></div>
                    <div className="sso-bt bt-facebook"></div>
                </div>
                <div className="col">
                    <div>Vous n'avez pas de compte ?</div>
                    {/* TODO Ajouter le lien */}
                    <Link onClick={() => handleScreenChange('createAccount')} >Créez-en un gratuitement</Link>
                </div>
            </div>
        </>
    );
};

export default ConnexionScreen;
