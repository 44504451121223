import React, { useEffect, useState } from 'react';
import Product from '../components/Product';
import Breadcrumb from '../components/Breadcrumb';
import Title from '../components/Title';
import BannerLicence from '../components/BannerLicence';
import { useParams } from "react-router-dom";
import function_licenceColorBackground from "../utils/function_licenceColorBackground";
import config_json from '../utils/config_json';

// TODO : A vérifier -  Supprimer  productPage et charger le composant <Product> dans licencePage -> pas de rechargement <BannerLicence> et récup des props plus facile (pas de nouveau fetch)


function ProductPage() {
    const [sortedData, setSortedData] = useState([]);
    const [donnees, setDonnees] = useState([]);
    const [useParamsIsLoaded, setUseParamsIsLoaded] = useState(false);
    const [itemsWithSameRef, setItemsWithSameRef] = useState(false);

    const { licence: slugLicence, figurine: slugFigurine } = useParams();


    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {
                const currentFigurine = data.filter((item) => item.slug_figurine === slugFigurine);
                setDonnees(currentFigurine[0]);
                setSortedData(currentFigurine);
                setUseParamsIsLoaded(true);

                const filteredLicenceData = data.filter((item) => item.licence === currentFigurine[0].licence);

                const filteredFigurines = [];

                for (const figurine of filteredLicenceData) {
                    // ignore les variantes de forme
                    if (figurine.ref === currentFigurine[0].ref && figurine.id !== currentFigurine[0].id) {
                        if (figurine.variant_form) {
                            continue;
                        }
                        // SI c'est une forme variante, ne afficher les editions alternatives
                        if (currentFigurine[0].variant_form || currentFigurine[0].ref === "Pack") {
                            filteredFigurines.length = 0; // Efface toutes les figurines déjà filtrées
                            break; // Termine la boucle
                        }
                        filteredFigurines.push(figurine);
                    }
                }


                setItemsWithSameRef(filteredFigurines);


            })
            .catch(error => console.log(error));
    }, [slugFigurine]);

    const logoLicence = donnees.logo_licence;
    const couleurLicence = donnees.couleur_licence;
    const licence = donnees.licence;
    // const slugLicence = donnees.slug_licence;
    const categoryName = "Licences"
    const slugCategory = "licences"


    function_licenceColorBackground(couleurLicence);

    return (

        <>
            <Title title={slugCategory} />

            {useParamsIsLoaded && (
                 <BannerLicence licence={licence} logoLicence={logoLicence} couleurLicence={couleurLicence} slugLicence={slugLicence}/>
            )}

            {useParamsIsLoaded && (
                <Breadcrumb
                    currentCategory={categoryName}
                    slugCategory={slugCategory}
                    licence={licence}
                    slugLicence={slugLicence}
                    slugFigurine={slugFigurine}
                />
            )}



            {useParamsIsLoaded && sortedData && sortedData.map((item) => (

                < Product
                    key={item.id}
                    id={item.id}

                    licence={item.licence}
                    nom={item.nom}
                    nom_ed={item.nom_ed}
                    figRef={item.ref}

                    date_sortie={item.date_sortie_aff}
                    date_sortie_tri={item.date_sortie_tri}
                    
                    id_funko={item.id_funko}

                    editions={item.editions}
                    ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                    ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                    ed_spe3={item.editions ? item.editions.ed_spe3 : null}

                    collection={item.collection}
                    variant_form={item.variant_form}

                    images={item.images}
                    image1={item.images.img_url1}

                    licenceColor={item.couleur_licence}

                    fav_state={item.fav_state}
                    add_state={item.add_state}

                    altEditions={itemsWithSameRef}

                />
            ))}


        </>
    );
};

export default ProductPage;