import React, { useState } from "react";
import { Link } from "react-router-dom";
import { function_createSlug } from "../utils/function_createSlug";
import { function_edSpeReplacementValues } from "../utils/function_edSpeReplacementValues";
import config_FontSize from '../utils/config_FontSize';

// import de variables de couleur
import variantFormStyle from "../styles/sass/main.css";

function FigurineCard(props) {

    const mediumNameLength = config_FontSize.mediumNameLength
    const longNameLength = config_FontSize.longNameLength
    const veryLongNameLength = config_FontSize.veryLongNameLength

    const [imageLoaded, setImageLoaded] = useState(true);

    const handleImageError = () => {
        setImageLoaded(false);
    };


    // affichage du sticker
    const stickersDisplay = (edition) => {
        return (
            edition === "" ? "" : <div className="sticker" >
                <img
                    src={"https://thomak.xyz/pop-collection/assets/images/stickers/50px/sticker-50-" + function_edSpeReplacementValues(function_createSlug(edition)) + ".png"}
                    alt={edition}
                />
            </div >
        )
    };

    const licenceAndvariant_style = {
        background: props.couleur_licence,
        border: props.variant_form && props.variant_form !== "Poster" ? `2px solid ${variantFormStyle.colorVariantFormTag}` : "",
        lineHeight: props.variant_form ? "0.9" : "1.1"
    };


    // formattage de la date pour le label "Date" des nouveautés
    const formatDate = (dateString) => {
        const months = [
            "Janv.", "Fév.", "Mars", "Avr.", "Mai", "Juin",
            "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."
        ];

        const [year, month] = dateString.split('-');
        const monthIndex = parseInt(month, 10) - 1; // Convertit le mois en indice (0-11)

        return `${months[monthIndex]} ${year}`;
    };




    return (
        <>
            <Link to={`/licences/${props.slug_licence}/${props.slug_figurine}`} className="figurineCard">

                <div className="card">



                    {/* //NOTE label alternatif */}
                    {/* <div className="label label-left" style={{ background: props.couleur_licence, border: "4px solid" + props.couleur_licence }}>{props.ref_funko}</div> */}
                    <div className="label label-ref" style={licenceAndvariant_style}>{props.ref_funko}</div>


                    {/* uniquement pour la page Nouveautés et les "sorties récentes" */}
                    {props.categoryName === "Nouveautés" ? <div className="label label-date recente">{formatDate(props.date_sortie_tri)}</div> : ""}

                    {/* uniquement pour la page Nouveautés et les dates "à venir" (ATTENTION: ne pas inverser le sens avec "sortie récente" sinon la props "future" ne s'applique pas (label jaune)  */}
                    {props.categoryName === "Nouveautés" || props.future ? <div className="label label-date future">{formatDate(props.date_sortie_tri)}</div> : ""}

                    {/* DEBUG - affiche la date de sortie pour toutes les figurines */}
                    {/* <div className="label label-date">{formatDate(props.date_sortie_tri)}</div> */}




                    <div className="card-content">
                        <div className="col-nom">
                            {/* Adaptation du corps du texte en fonction du nomnre de caractères */}
                            <div className={'nom ' + (props.nom.length > veryLongNameLength ? 'very-long-name' : props.nom.length > longNameLength ? 'long-name' : props.nom.length > mediumNameLength ? 'medium-name' : 'short-name')}>{props.nom}</div>

                            {/* couleur en fonction de la variante : Digital = vert, tous les autres excepté Poster sont bleus */}
                            <div className="nom-edition" style={
                                props.variant_form && props.variant_form !== "Poster"
                                    ? (props.variant_form === "Digital" ? { color: variantFormStyle.colorDigitalTag } : { color: variantFormStyle.colorVariantFormTag })
                                    : undefined
                            }>{props.nom_ed}</div>




                            <div className="nom-BG nonSelectionnable">{props.nom}</div>
                        </div>
                        <div className="card-image figurine-image">
                            <div className="image-container">

                                {imageLoaded ? (
                                    <img
                                        src={props.image}
                                        onError={handleImageError}
                                        alt=""
                                    />
                                ) : (

                                    // affiche une image de remplacement si l'image est introuvable
                                    // eslint-disable-next-line jsx-a11y/img-redundant-alt                                    
                                    <img src="https://thomak.xyz/pop-collection/assets/images/products/missing-image.jpg" alt="image manquante" title="image manquante" />

                                )}


                                {/* NOTE : hover GITD */}
                                {/* au survol, l'image de la figurine GITD est remplacée par la version "in the dark" */}
                                {/* {props.imageHover && props.editions && props.ed_spe1 === "Glow in the Dark" ?
                <>
                    <img className="image-hover"
                        src={props.imageHover}
                        alt={props.nom}
                    />
                </>
                : null} */}
                            </div>
                        </div>
                        <div className="row-superpose">
                            <div className="col-pictos-actions">
                                <div className={`picto ${props.fav_state ? 'picto-fav-active' : 'picto-fav'}`}></div>
                                <div className={`picto ${props.add_state ? 'picto-add-active' : 'picto-add'}`}></div>
                            </div>


                            <div className="col-stickers">
                                {props.editions && props.ed_spe3 && stickersDisplay(props.ed_spe3)}
                                {props.editions && props.ed_spe2 && stickersDisplay(props.ed_spe2)}
                                {props.editions && stickersDisplay(props.ed_spe1)}
                            </div>

                        </div>
                    </div>

                    <div className="card-bg">
                        <img src="https://thomak.xyz/pop-collection/assets/images/card.svg" alt="" />
                    </div>
                </div>


            </Link >
        </>

    );


}


export default FigurineCard;