import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import AccountPage from '../pages/AccountPage';
import LicencesPage from '../pages/LicencesPage';
import LicencePage from '../pages/LicencePage';
import ProductPage from '../pages/ProductPage';
import CollectionPage from '../pages/CollectionPage';
import CollectionLicencePage from '../pages/CollectionLicencePage';
import LastReleasesPage from '../pages/LastReleasesPage';
import LastReleasesPageDetail from '../pages/LastReleasesPageDetail';
import SpecialEditionsPage from '../pages/SpecialEditionsPage';
import SpecialEditionPage from '../pages/SpecialEditionPageDetail';
import UniversPage from '../pages/UniversPage';
import Page404 from '../pages/Page404';

import Debug from '../pages/Debug';
// import SearchBar from '../components/Search';

function Roads() {
    return (
        <>

            <Routes>
                <Route exact path="/" Component={HomePage} />
                <Route path="/mon-compte" Component={AccountPage} />

                {/* <Route path="/search" Component={SearchBar} /> */}

                <Route exact path="/licences" Component={LicencesPage} />
                <Route exact path="/licences/:licence" Component={LicencePage} />
                <Route exact path="/licences/:licence/:figurine" Component={ProductPage} />
                <Route exact path="/ma-collection" Component={CollectionPage} />
                <Route exact path="/ma-collection/:licence/:type" Component={CollectionLicencePage} />
                <Route exact path="/nouveautes" Component={LastReleasesPage} />
                <Route exact path="/nouveautes/:licence" Component={LastReleasesPageDetail} />
                <Route exact path="/editions-speciales" Component={SpecialEditionsPage} />
                <Route exact path="/editions-speciales/:edition" Component={SpecialEditionPage} />
                <Route exact path="/univers" Component={UniversPage} />

                {/* //DEBUG */}
                <Route exact path="/debug" Component={Debug} />

                <Route path="*" Component={Page404} />

            </Routes>
        </>
    );
};

export default Roads;