export function function_cards_sort(rawData) {


    // Tri initial par référence
    const sortedDataByRef = [...rawData].sort((a, b) => {
        const refA = parseInt(a.ref);
        const refB = parseInt(b.ref);
        return refA - refB;
    });

    // Regroupement par référence avec identifiant unique
    const groupedDataMap = {};
    const groupedData = [];

    sortedDataByRef.forEach(currentItem => {
        const reference = currentItem.ref;
        const variantForm = currentItem.variant_form;

        // Exclure les Rides, Pack et Town
        if (
            reference !== "Pack" &&
            variantForm !== "Rides" &&
            variantForm !== "Town"
        ) {
            if (!groupedDataMap[reference]) {
                groupedDataMap[reference] = [currentItem];
            } else {
                groupedDataMap[reference].push(currentItem);
            }
        } else {
            groupedData.push([currentItem]);
        }
    });

    // Création du tableau final trié
    for (const reference in groupedDataMap) {
        const group = groupedDataMap[reference];

        // Trier chaque paire par date la plus ancienne
        group.sort((a, b) => {
            const dateA = new Date(a.date_sortie_tri);
            const dateB = new Date(b.date_sortie_tri);
            return dateB - dateA;
        });

        // Trier la paire en plaçant la figurine classique en premier puis les "editions spaciales"
        group.sort((a) => {
            const aIsUndefined = a.editions === undefined ||
                (a.editions && a.editions.ed_spe1 && a.editions.ed_spe1.includes("Sized")) ||
                (a.editions && a.editions.ed_spe2 && a.editions.ed_spe2.includes("Sized")) ||
                (a.editions && a.editions.ed_spe3 && a.editions.ed_spe3.includes("Sized"));

            if (aIsUndefined) {
                return -1;
            } else {
                return 1;
            }
        });

        groupedData.push(group);
    }

    // Trier les paires par date de la première occurrence dans chaque paire
    groupedData.sort((a, b) => {
        const firstDateA = new Date(a[0].date_sortie_tri);
        const firstDateB = new Date(b[0].date_sortie_tri);
        return firstDateA - firstDateB;
    });

    // Résultat final avec les paires triées
    const sortedData = [].concat(...groupedData);

    return sortedData

}
export default function_cards_sort