import React, { useState } from 'react'
import BoutonActionProduct from './BoutonActionProduct';
import { Link } from 'react-router-dom';
import { function_edSpeReplacementValues } from '../utils/function_edSpeReplacementValues';
import { function_createSlug } from '../utils/function_createSlug';
import { isDateGreaterThanToday } from '../utils/function_isDateGreaterThanToday';
import config_FontSize from '../utils/config_FontSize';


function Product(props) {
    const [mainImage, setMainImage] = useState(props.image1);

    const [button1IsActive, setButton1IsActive] = useState(props.add_state);
    const [button2IsActive, setButton2IsActive] = useState(props.fav_state);

    const longProductNameLength = config_FontSize.longProductNameLength
    const mediumProductNameLength = config_FontSize.mediumProductNameLength


    // Debug: under construction
    const displayUnderConstruction = () => {
        const message = document.querySelector('.under-construction-product');
        if (message) {
            message.style.display = 'block';

            setTimeout(function () {
                message.style.display = 'none';
            }, 3500);
        }
    }




    const activateButton1 = () => {
        setButton1IsActive(!button1IsActive);
        if (button2IsActive) {
            setButton2IsActive(false);
        }

        // Debug 
        displayUnderConstruction()
    }


    const activateButton2 = () => {
        setButton2IsActive(!button2IsActive);
        if (button1IsActive) {
            setButton1IsActive(false);
        }

        // Debug 
        displayUnderConstruction()
    };


    // défini l'image principale en fonction de l'image cliquée
    const handleClick = (e) => {
        const newMainImage = e.target.src;
        e.target.src = mainImage;
        setMainImage(newMainImage);
    }

    // vérifie si date est ultérieure à aujourd'hui
    const isGreater = isDateGreaterThanToday(props.date_sortie_tri);


    // Liste des ref identiques mais qui n'ont pas de lien
    const sameRefExceptions = [
        "44867-FL",
        "46994-TL",
        "11915",
        "14733"
    ]


    return (
        <>
            <div className="product">
                <div className="product-container">
                    <div className="col-left">
                        <div className="id-container">
                            <div className="name-container isMobile">
                                <h2 className="licence" style={{ color: props.licenceColor }}>{props.licence}</h2>
                                <div className={'nom ' + (props.nom.length > 45 ? 'long-product-name' : props.nom.length > 35 ? 'medium-product-name' : 'short-product-name')}>{props.nom}</div>
                                <div className="nom-edition">{props.nom_ed}</div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-content">
                                <div className="card-image">


                                    <div className="image-container">
                                        <img src={mainImage} alt={`Figurine Funko Pop ${props.licence} ${props.nom} ${props.nom_ed ? `(${props.nom_ed})` : ''}`} />
                                    </div>

                                </div>
                                <div className="row-superpose">
                                    <div className="col-ref">
                                        <div className="ref" style={{ backgroundColor: props.licenceColor }}>{props.figRef}</div>
                                    </div>

                                    <div className="col-stickers">
                                        {props.editions &&
                                            Object.values(props.editions).map((edition, index) => {
                                                const editionNumber = index + 1;
                                                return (
                                                    edition && (
                                                        <div className="sticker" key={editionNumber}>
                                                            <img
                                                                src={`https://thomak.xyz/pop-collection/assets/images/stickers/60px/sticker-60-${function_edSpeReplacementValues(
                                                                    function_createSlug(edition)
                                                                )}.png`}
                                                                alt={`Edition ${edition}`}
                                                                title={`Edition "${edition}"`}
                                                            />
                                                        </div>
                                                    )
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>

                            <div className="card-bg">
                                <img src="https://thomak.xyz/pop-collection/assets/images/card-product_page.svg" alt="" />
                            </div>
                        </div>

                        {/* Nom vertical dans le fond */}
                        <div className="nom-bg-container">
                            <span className="nom-bg nonSelectionnable">{props.nom}</span>
                        </div>

                        <div className="strip isMobile" style={{ backgroundColor: props.licenceColor }}></div>

                    </div >
                    <div className="col-right">
                        <div className="id-container">
                            <div className="name-container isDesktop">
                                <h2 className="licence" style={{ color: props.licenceColor }}>{props.licence}</h2>
                                <div className={'nom ' + (props.nom.length > longProductNameLength ? 'long-product-name' : props.nom.length > mediumProductNameLength ? 'medium-product-name' : 'short-product-name')}>{props.nom}</div>
                                <div className="nom-edition">{props.nom_ed}</div>
                            </div>

                            <div className="details">
                                <div className="date-container">
                                    <div className="picto-date" style={{ backgroundColor: props.licenceColor }}> </div>
                                    <div className={isGreater ? "date future" : "date"}>{props.date_sortie}</div>

                                </div>
                                {props.id_funko ? (
                                    <div className="f_ref-container">
                                        <div className="picto-f_ref" style={{ backgroundColor: props.licenceColor }}></div>
                                        <div className="f_ref">Ref Funko : <span>{props.id_funko}</span></div>
                                    </div>)
                                    : ""}
                            </div>

                            <div className="row">
                                <div className="tag collection"><span>{props.collection}</span></div>
                                {props.variant_form && <div className={"tag " + (props.variant_form === "Digital" ? "digital" : "variant-form")}><span>{props.variant_form}</span></div>}
                            </div>


                            <div className="fav-container">
                                <div className='tooltip-container'>
                                    <BoutonActionProduct
                                        pictoClass="picto-add"
                                        pictoClassActive="picto-add-active"
                                        colorClass="addColor"
                                        state={button1IsActive}
                                        otherIsActive={button2IsActive}
                                        toggleOther={() => setButton2IsActive(false)}
                                        onClick={activateButton1}
                                        label="Ajouter à ma collection"
                                    />

                                    <span className='tooltip'>{button1IsActive ? "Supprimer de ma collection" : "Ajouter à ma collection"}</span>
                                </div>

                                <div className='tooltip-container'>
                                    <BoutonActionProduct
                                        pictoClass="picto-fav"
                                        colorClass="favColor"
                                        state={button2IsActive}
                                        otherIsActive={button1IsActive}
                                        toggleOther={() => setButton1IsActive(false)}
                                        onClick={activateButton2}
                                        label="Ajouter à ma liste de souhaits"
                                    />
                                    <span className='tooltip'>{button2IsActive ? "Supprimer de ma liste de souhaits" : "Ajouter à ma liste de souhaits"}</span>
                                </div>


                                {(!button1IsActive && !button2IsActive) && (
                                    <span className='tooltip'>
                                        Aucun bouton actif
                                    </span>
                                )}

                                {/* DEBUG : under construction */}
                                <div className="under-construction-product">
                                    🚧 Valeur arbitraire pour test. <br /> Non prise en compte pour le moment
                                </div>
                            </div>
                        </div>

                        <div className="mini-cards-container">
                            {Object.keys(props.images)
                                .filter(function (key) {
                                    return key !== "img_url1" && key.startsWith("img_url") && props.images[key] !== "";
                                })
                                .map(function (key) {
                                    return (
                                        <div className="mini-card" key={key}>
                                            <div className="mini-image">
                                                <img
                                                    src={props.images[key]}
                                                    alt={`Figurine Funko Pop ${props.licence} ${props.nom} ${props.nom_ed ? props.nom_ed : ""}`}
                                                    title={`Figurine Funko Pop ${props.licence} ${props.nom} ${props.nom_ed ? props.nom_ed : ""}`}
                                                    onClick={handleClick}
                                                />
                                            </div>
                                            <div className="card-bg card-bg-mini">
                                                <img src="https://thomak.xyz/pop-collection/assets/images/card-product_page.svg" alt="" />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div className="strip isDesktop" style={{ backgroundColor: props.licenceColor }}></div>
                </div>



                {/* Versions alternatives */}
                {!sameRefExceptions.includes(props.id) && props.altEditions.length > 0 ?
                    <div className="alt-editions">
                        <div className={`alt-editions-bloc ${props.altEditions.length === 1 ? "one-card" : ""}`}>
                            <div className="alt-editions-bloc-text">
                                Existe aussi dans {props.altEditions.length > 1 ? "d'autres versions" : "une autre version"}
                            </div>
                            <div className="mini-cards-container">
                                {props.altEditions.map((item) => (
                                    <Link to={`/licences/${item.slug_licence}/${item.slug_figurine}`} className="mini-card" key={item.id} >
                                        <div className="row-superpose alt-versions">
                                            <div className={"col-ref"}>
                                                <div className="ref alt-versions" style={{ backgroundColor: item.couleur_licence }}>{item.ref}</div>
                                            </div>

                                            <div className="col-stickers">
                                                {item.editions &&
                                                    Object.values(item.editions)
                                                        .slice(0, 2) // Limite à 2 éléments
                                                        .map((edition, index) => {
                                                            const editionNumber = index + 1;
                                                            return (
                                                                edition && (
                                                                    <div className="sticker alt-versions" key={editionNumber}>
                                                                        <img
                                                                            src={`https://thomak.xyz/pop-collection/assets/images/stickers/50px/sticker-50-${function_edSpeReplacementValues(
                                                                                function_createSlug(edition)
                                                                            )}.png`}
                                                                            alt={`Edition ${edition}`}
                                                                            title={`Edition "${edition}"`}
                                                                        />
                                                                    </div>
                                                                )
                                                            );
                                                        })}
                                            </div>

                                        </div>


                                        <div className="mini-image alt-versions">
                                            <img
                                                src={item.images.img_url1}
                                                alt={`Figurine Funko Pop ${item.licence} ${item.nom} ${item.nom_ed ? item.nom_ed : ""}`}
                                                title={`Figurine Funko Pop ${item.licence} ${item.nom} ${item.nom_ed ? item.nom_ed : ""}`}
                                            />
                                        </div>
                                        <div className="card-bg-mini">
                                            <img src="https://thomak.xyz/pop-collection/assets/images/card-product_page.svg" alt="" />
                                        </div>
                                        {/* <img src={item.images.img_url1} alt="" /> */}
                                    </Link>

                                ))}
                            </div>
                        </div>
                    </div >

                    : ""
                }
            </div>

        </>
    )
}




export default Product