import React from "react";
import ReusableFigCardsListPage from '../pages/ReusableFigCardsListPage';


function LicencePage() {

    const categoryName = "Licences"
    const slugCategory = "licences"

    return (
        <>
            <ReusableFigCardsListPage categoryName={categoryName} slugCategory={slugCategory} />
        </>
    );
};

export default LicencePage;