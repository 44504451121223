import React from 'react';
import { Link } from 'react-router-dom';

const DeleteAccountValidationScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Votre compte a bien été supprimé</div>
                <div className="picto"><img src="https://thomak.xyz/pop-collection/assets/images/account/picto-sad.png" alt="" /></div>
                <Link to="/" >Retour à l'accueil</Link>
            </div>
        </>
    );
};

export default DeleteAccountValidationScreen;
