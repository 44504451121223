import React from 'react';
import { Link } from 'react-router-dom';

const CreateAccountScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Créez un compte Pop Collection<br />
                    <span>pour gérer votre collection de figurines Pop</span></div>
                <form>
                    <input type="text" placeholder="Pseudo" />
                    <input type="email" placeholder="Adresse email" />
                    <input type="password" placeholder="Mot de passe" />
                </form>
                <div className="button button_primary" onClick={() => handleScreenChange('user')}>Créer un compte</div>
                <span>ou</span>
                <div className="row">
                    <div className="sso-bt bt-google"></div>
                    <div className="sso-bt bt-facebook"></div>
                </div>
                <div className="col">
                    <div>En fait, j'ai déjà un compte 😅</div>
                    {/* TODO Ajouter le lien */}
                    <Link onClick={() => handleScreenChange('connexion')} >Connectez-vous</Link>
                </div>
            </div>
        </>
    );
};

export default CreateAccountScreen;
