import React, { useState, useEffect, useRef } from "react";
import FigurineCard from "./FigurineCard";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import function_cards_sort from "../utils/function_cards_sort";
import config_json from '../utils/config_json';


function CollectionFigurineCardsList(props) {

    const { type } = useParams(); // Récupérer le paramètre de l'URL (wishlist ou collection)
    const navigate = useNavigate();

    const [itemCount, setItemCount] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [totalElementCount, setTotalElementCount] = useState([]);
    const [isActiveWishlist, setIsActiveWishlist] = useState(false);
    const [isActiveCollection, setIsActiveCollection] = useState(false);
    const [sortOrder, setSortOrder] = useState("desc");

    const [loadingData, setLoadingData] = useState(true);
    const [error, setError] = useState(false);

    const filteredDataByFavItem = useRef([]);
    const filteredDataByAddItem = useRef([]);


    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)

            .then(response => response.json())
            .then(data => {

                // Tri des données par licence
                const dataByLicence = data.filter(item => item.slug_licence === props.slugLicence);
                setTotalElementCount(dataByLicence.length);

                // Tri des données par élément de la collection
                filteredDataByAddItem.current = dataByLicence.filter((item) => item.add_state === true);
                filteredDataByFavItem.current = dataByLicence.filter((item) => item.fav_state === true);



                // Vérifie l'état (Collection ou Wishlist) et active ou non les boutons
                let filteredData = [];
                if (type === "wishlist") {
                    filteredData = filteredDataByFavItem.current;
                    setIsActiveWishlist(true);
                } else if (type === "collection") {
                    filteredData = filteredDataByAddItem.current;
                    setIsActiveCollection(true);
                } else {
                    // Valeur "type" invalide, afficher tous les éléments
                    filteredData = dataByLicence;
                }

                // Tri des données par date
                filteredData.sort((b, a) => {
                    // Tri par date
                    if (a.date_sortie_tri !== b.date_sortie_tri) {
                        return a.date_sortie_tri.localeCompare(b.date_sortie_tri);
                    }

                    // Tri par ID si les dates sont identiques
                    return a.ref - b.ref;
                });



                const sortedData = function_cards_sort(filteredData)

                // Trier les éléments filtrés en fonction de sortOrder
                const sortedByDesc = sortOrder === "asc" ? sortedData : [...sortedData].reverse();

                setFilteredData(sortedByDesc);

                // Compte le nombre d'éléments dans la liste
                setItemCount(sortedByDesc.length);


                setLoadingData(false);
                setError(false);
            })

            .catch(error => {
                // Afficher l'erreur dans la console
                console.log(error);

                // Mettre à jour les variables d'état en cas d'erreur
                setLoadingData(false);
                setError(true);
            })
    }, [props.slugLicence, type, sortOrder]);


    const handleWishlistClick = () => {
        setFilteredData(filteredDataByAddItem.current);
        navigate(`/ma-collection/${props.slugLicence}/wishlist`, { state: { licence: props.licence } });
        setIsActiveWishlist(true);
        setIsActiveCollection(false);
    };

    const handleCollectionClick = () => {
        setFilteredData(filteredDataByFavItem.current);
        navigate(`/ma-collection/${props.slugLicence}/collection`, { state: { licence: props.licence } });
        setIsActiveCollection(true);
        setIsActiveWishlist(false);
    };


    const handleToggleSort = () => {
        if (sortOrder === "asc") {
            setSortOrder("desc")
        } else {
            setSortOrder("asc")
        }
    };


    if (loadingData) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Chargement des figurines...</div >;
    }

    if (error) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Une erreur s'est produite lors du chargement des données. <br />
            Veuillez recharger la page.</div >;
    }
    return (
        <>


            <div className="filtres">
                <div className={`nb-fig-total color-add ${isActiveCollection && type === "collection" ? "is-active" : "hover-active"}`} onClick={isActiveCollection ? null : handleCollectionClick}>
                    {filteredDataByAddItem.current.length} <span>figurines <br />possédées</span>
                </div>
                <div className="separateur"></div>
                <div className={`nb-fig-total color-fav ${isActiveWishlist && type === "wishlist" ? "is-active" : (filteredDataByFavItem.current.length === 0 ? "" : "hover-active")}`} onClick={isActiveWishlist ? null : (filteredDataByFavItem.current.length === 0 ? null : handleWishlistClick)}>
                    {filteredDataByFavItem.current.length} <span>figurines <br />souhaitées</span>
                </div>
                <div className='separateur'></div>
                <div className='nb-fig-total color-dark'>
                    {totalElementCount}<span>figurines <br />au total</span>
                </div>
            </div >


         {/* Titres */}
         <div className="my-collection-titles">{isActiveCollection ?
                <div className="my-collection-titles_collection">Figurines possédées</div> :
                <div className="my-collection-titles_wishlist">Figurines souhaitées</div>
            }</div>


            <div className="sort-by-date" onClick={handleToggleSort}>
                <div className="color-mask"></div>
                <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-hashtag-square.svg" alt="" />
                {sortOrder === "asc" ? (
                    <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-1-to-9.svg" alt="" />

                ) : (
                    <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-9-to-1.svg" alt="" />)}
            </div>


            <div className={`cards-container card-grid-big ${itemCount <= 4 ? 'uncomplete-row' : ''}`}>
                {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item) => (
                        <FigurineCard
                            key={item.id}
                            id={item.id}
                            id_funko={item.id_funko}
                            ref_funko={item.ref}

                            nom={item.nom}
                            nom_ed={item.nom_ed}
                            licence={item.licence}
                            couleur_licence={item.couleur_licence}

                            image={item.images.img_url1}
                            imageHover={item.images.img_url3}

                            editions={item.editions}
                            ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                            ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                            ed_spe3={item.editions ? item.editions.ed_spe3 : null}
                            variant_form={item.variant_form}

                            date_sortie={item.date_sortie_aff}

                            // debug
                            date_sortie_tri={item.date_sortie_tri}

                            fav_state={item.fav_state}
                            add_state={item.add_state}

                            slug_figurine={item.slug_figurine}
                            slug_licence={item.slug_licence}
                        />
                    ))
                ) : (
                    "Aucun élément à afficher"
                )}
            </div>
        </>
    );
}

export default CollectionFigurineCardsList;