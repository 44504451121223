import React from 'react';
import LicencesCardsList from '../components/LicencesCardsList';
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';

function LicencesPage() {

    const categoryName = "Licences"
    const slugCategory = "licences"


    return (
        <>

            <Title title={slugCategory} className="page-title" />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />

            <section className='licences-page'>
                <LicencesCardsList />
            </section>

        </>
    );
};

export default LicencesPage;