import React, { useState, useEffect } from "react";
import FigurineCard from "./FigurineCard";
import { function_edSpeReplacementValues } from "../utils/function_edSpeReplacementValues";
import function_cards_sort from "../utils/function_cards_sort";
import config_json from '../utils/config_json';



function SpecialEditionFigurineCardsList(props) {
    const [donnees, setDonnees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState("desc");



    useEffect(() => {
        if (props.edition) {
            fetch(config_json.jsonFigurinesURL)
                .then(response => response.json())
                .then(data => {

                    // Filtre les données par le nom de l'édition ou de la variante
                    const donneesFiltrees = data.filter(
                        (item) =>
                            item.editions?.ed_spe1 === props.edition ||
                            item.editions?.ed_spe2 === props.edition ||
                            item.editions?.ed_spe3 === props.edition ||
                            item.variant_form === props.edition
                    );


                    // todo : trier par popularité par la suite
                    // Tri des données par licence et par date de sortie
                    donneesFiltrees.sort((a, b) => {
                        if (a.licence !== b.licence) {
                            return a.licence.localeCompare(b.licence);
                        }
                        if (a.date_sortie_tri !== b.date_sortie_tri) {
                            return a.date_sortie_tri.localeCompare(b.date_sortie_tri);
                        }
                        return a.ref - b.ref;
                    })


                    const sortedData = function_cards_sort(donneesFiltrees)

                    // Trier les éléments filtrés en fonction de sortOrder
                    const sortedByDesc = sortOrder === "asc" ? sortedData : [...sortedData].reverse();



                    // Grouper les cartes par licence 
                    const groupesParLicence = sortedByDesc.reduce((groupes, item) => {
                        const licence = item.licence;
                        if (!groupes[licence]) {
                            groupes[licence] = [];
                        }
                        groupes[licence].push(item);
                        return groupes;
                    }, {});

                    setIsLoading(false);
                    setDonnees(groupesParLicence);
                })
                .catch(error => console.log(error));
        }
    }, [props.edition, sortOrder]);



    return (
        <>
            {isLoading ? (
                <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Chargement des figurines...</div >
            ) : (
                Object.keys(donnees).map(licence => {
                    const cartes = donnees[licence];
                    // couleur de la première carte du groupe
                    const couleur_licence = cartes[0].couleur_licence;

                    return (
                        <div className="groupCardsByLicence" key={licence}>
                            <div className="licence-title" style={{ backgroundColor: couleur_licence }}>
                                <h2>{licence}</h2>
                                <div className="sticker-edition">
                                    <img src={`https://thomak.xyz/pop-collection/assets/images/stickers/120px/sticker-120-${function_edSpeReplacementValues(props.slugEdition)}.png`} alt="" />
                                </div>
                                <div className="count">{cartes.length}</div>

                            </div>
                            <div className={`cards-container card-grid-big ${cartes.length < 4 ? 'uncomplete-row' : ''}`}>
                                {cartes.map((item) => (
                                    <FigurineCard
                                        key={item.id}
                                        id={item.id}
                                        id_funko={item.id_funko}
                                        ref_funko={item.ref}

                                        nom={item.nom}
                                        nom_ed={item.nom_ed}
                                        licence={item.licence}
                                        couleur_licence={item.couleur_licence}

                                        image={item.images.img_url1}
                                        imageHover={item.images.img_url3}

                                        editions={item.editions}
                                        ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                                        ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                                        ed_spe3={item.editions ? item.editions.ed_spe3 : null}
                                        variant_form={item.variant_form}

                                        date_sortie={item.date_sortie_aff}

                                        // debug
                                        date_sortie_tri={item.date_sortie_tri}

                                        fav_state={item.fav_state}
                                        add_state={item.add_state}

                                        slug_figurine={item.slug_figurine}
                                        slug_licence={item.slug_licence}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                })
            )}
        </>
    );
}

export default SpecialEditionFigurineCardsList;



