import React from 'react';
import { Link } from 'react-router-dom';

const ReinitPasswordScreenSendEmail = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Mot de passe oublié ?</div>
                <span>Un email a été envoyé à l'adresse email <i>bomberX@gmail.com</i>.<br />
                    Cliquez sur le lien pour créer un nouveau mot de passe.</span>
                <Link onClick={() => handleScreenChange('connexion')} >Retour à la page de connexion</Link>
            </div>
        </>
    );
};

export default ReinitPasswordScreenSendEmail;
