import React from 'react';
import Title from '../components/Title';


function Page404() {

    return (
        <>
            <Title title={"erreur"} />

            <div className="big-window">
                <div className="big-window-col1">
                    <div className="big-window-col1-content">
                        <div className="visuel-404">
                            <img src="https://thomak.xyz/pop-collection/assets/images/404/404.png" alt="" />
                        </div>
                        <div className='msg-404'>Désolé, la page que vous voulez consulter n'est pas disponible</div>
                    </div>
                </div>
                <div className="big-window-col2 gradient_404">
                    <div className="big-window-col2-image">
                        <img src="https://thomak.xyz/pop-collection/assets/images/404/perso-404.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Page404;