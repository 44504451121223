import React, { useEffect, useRef, useState } from 'react';

function BoutonActionProduct(props) {
    const btActionRef = useRef(null);
    const [isActive, setIsActive] = useState(props.state);


    useEffect(() => {
        setIsActive(props.state);
    }, [props.state]);

    useEffect(() => {
        const btActionContainer = btActionRef.current;
        btActionContainer.classList.toggle("bt-active", isActive);

        const handleClick = () => {
            if (!isActive) {
                props.toggleOther();
            }
            props.onClick();
            setIsActive(!isActive);
        };

        btActionContainer.addEventListener("click", handleClick);

        return () => {
            btActionContainer.removeEventListener("click", handleClick);
        };
    }, [isActive, props]);


    // Permet de definir le picto utilisé dans le bouton, le picto par défaut est picto-fav
    // Même composant, juste le picto qui change et la couleur active
    const pictoClass = props.pictoClass || 'picto-fav';
    const colorClass = props.colorClass || 'favColor';  //favColor = valeur par défaut


    return (
        <>
            <div ref={btActionRef} className={`bt-action-container ${isActive ? "bt-active" : ""}`}>
                <div className="picto-zone">
                    <div className={`picto ${pictoClass} ${isActive ? "picto-add-active" : ""}`}></div>
                    <div className="elipseHover"></div>
                    <div className={`elipseActive ${colorClass}`}></div>
                </div>
                <div className='bt-label'>{props.label}</div>
            </div>
        </>
    );
}

export default BoutonActionProduct;