import React from 'react';

const ReinitPasswordScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Mot de passe oublié ?</div>
                <span>Saisissez l'email lié à votre compte <strong>Pop Collection</strong></span>
                <form action="">
                    <input type="email" placeholder="Adresse email" />
                </form>
                <div className="button button_primary" onClick={() => handleScreenChange('reinitPasswordSendEmail')}  >Réinitialiser le mot de passe</div>
            </div>
        </>
    );
};

export default ReinitPasswordScreen;
