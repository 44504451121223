import React from 'react'
import { Link } from 'react-router-dom'

function BannerLicence(props) {
    return (
        <>
            <Link to={`/licences/${props.slugLicence}`} className="bannerLicence fade-in-image" style={{ backgroundColor: props.couleurLicence }}>
                <div className="bannerHalo"></div>
                <img src={props.logoLicence + "-big.png"} alt="" className='bannerLogo' />
            </Link>
        </>

    )
}

export default BannerLicence