import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";


function UnderConstructionBanner({ isSearchBarOpen }) {

    const [visibleConstructionBanner, setvisibleConstructionBanner] = useState(true);
    const banner1Height = 65;
    const marginTop = visibleConstructionBanner ? `${banner1Height}px` : '0';

    // FIXME : régler probleme espace à réduire /augmenter en fonction du champ de recherche affiché / masqué
    // const defaultMarginTop = isSearchBarOpen ? "148px" : "100px"; // Modification de la valeur de defaultMarginTop en fonction de isSearchBarOpen


    // A activer si under construction est actif
    // const defaultMarginTop = "75px";
    const defaultMarginTop = "148px";


    useEffect(() => {
        const headerClass = document.querySelector('.header');
        const mainClass = document.querySelector('.main');

        headerClass.style.marginTop = marginTop;
        mainClass.style.marginTop = marginTop + defaultMarginTop;

    }, [visibleConstructionBanner, marginTop, defaultMarginTop]);


    
    // Localstorage sur l'état "hide" de la bannière
    useEffect(() => {
        const isBannerClosed = localStorage.getItem('constructionBannerClosed');
        if (isBannerClosed === 'true') {
            setvisibleConstructionBanner(false);
        }
    }, []);


    const hideBanner = () => {
        localStorage.setItem('constructionBannerClosed', 'true');
        setvisibleConstructionBanner(false);
    };



    return (
        <>
            {visibleConstructionBanner && <div className="under-construction-banner">
                <div><img src="https://thomak.xyz/pop-collection/assets/images/perso-under-construction.png" alt="" /></div>
                <div><strong style={{ fontSize: "1.4em" }}>Ce site est en cours de construction</strong><br />Seul un échantillon de figurines est utilisé pour test. Design non définitif. Merci pour votre compréhension</div>

                <div className='bt' onClick={hideBanner}>
                    <FontAwesomeIcon icon={faXmark} />
                </div>
            </div>}


        </>
    )

}

export default UnderConstructionBanner



