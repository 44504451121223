export function function_edSpeReplacementValues(edition) {

    // valeur de remplacement des noms de stickers (FR -> US) pour appliquer les noms des images
    const stickersNameFr = ['diamant', 'argent', 'dore', 'noir-et-blanc', 'metallique', 'patine', 'translucide', 'bois'];
    const stickersNameUs = ['diamond', 'silver', 'gold', 'black-and-white', 'metallic', 'patina', 'translucent', 'wood'];

    // Si le nom d'édition est dans le tableau stickersNameFr, alors on récupère la valeur correspondante dans le tableau stickersNameUs, sinon on récupère la valeur d'origine
    return stickersNameFr.includes(edition) ? stickersNameUs[stickersNameFr.indexOf(edition)] : edition;

}



