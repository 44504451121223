import React, { useState, useEffect } from "react";
import SpecialEditionsStickerCard from "./SpecialEditionsStickerCard";
import config_json from '../utils/config_json';



function SpecialEditionsStickerCardsList() {

    const [figurines, setFigurines] = useState([]);
    const [editionsSpeciales, setEditionsSpeciales] = useState([]);

    const [loadingData, setLoadingData] = useState(true);
    const [error, setError] = useState(false);


    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {
                setFigurines(data);

                setLoadingData(false)
                setError(false)
            })
            .catch(error => {
                console.log(error);

                setLoadingData(false);
                setError(true);
            })

        fetch(config_json.jsonEdSpeURL)
            .then(response => response.json())
            .then(data => {
                setEditionsSpeciales(data);

                setLoadingData(false)
                setError(false)
            })
            .catch(error => {
                console.log(error);

                setLoadingData(false);
                setError(true);
            })
    }, []);



    if (loadingData) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Chargement des editions...</div >;
    }

    if (error) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Une erreur s'est produite lors du chargement des données. <br />
            Veuillez recharger la page.</div >;
    }
    return (
        <div className="cards-container card-grid-big">
            {editionsSpeciales.map(edition => {
                const figurinesCorrespondantes = figurines.filter(
                    figurine => ((figurine.editions && ((figurine.editions.ed_spe1 === edition.edition) || (figurine.editions.ed_spe2 === edition.edition) || (figurine.editions.ed_spe3 === edition.edition))) || (figurine.variant_form && figurine.variant_form === edition.edition))
                );
                const hasFigurinesCorrespondantes = figurinesCorrespondantes.length > 0;


                return (

                    <div key={edition.id}>
                        <SpecialEditionsStickerCard
                            key={edition.id}
                            // id={edition.id}

                            edition={edition.edition}
                            edition_slug={edition.edition_slug}
                            image={edition.img_url}

                            notAvailable={!hasFigurinesCorrespondantes}
                        />
                    </div>



                );
            })}
        </div>
    );





}

export default SpecialEditionsStickerCardsList;