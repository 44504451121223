import React, { useEffect, useState } from 'react'
import CollectionLicenceCard from './CollectionLicenceCard'
import config_json from '../utils/config_json';


function CollectionLicencesCardsList() {
    const [rawData, setRawData] = useState([]);
    const [figurinesCollectionData, setFigurinesCollectionData] = useState([]);
    const [licences, setLicences] = useState([]);
    const [itemCount, setItemCount] = useState([]);



    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {

                const figurinesCollectionData = data.filter(item => item.add_state === true);

                const uniqueLicenses = new Set(figurinesCollectionData.map(item => item.licence));
                setRawData(data);
                setFigurinesCollectionData(figurinesCollectionData);
                setLicences(Array.from(uniqueLicenses));
                setItemCount(Array.from(uniqueLicenses).length);

            })
            .catch(error => console.log(error));
    }, [itemCount]);





    const getLicenseCount = (licence) => {
        const licenceCount = figurinesCollectionData.reduce((count, item) => {
            return item.licence === licence ? count + 1 : count;
        }, 0);
        return licenceCount;
    };


    // Permet de compter le nombre global de figurines, toutes licences confondues, et le nombre total de licences dans la collection
    const collectionElementCount = figurinesCollectionData.length;
    const totalLicenseCount = licences.length;



    // Permet de compter le nombre total de figurines par licence
    const getTotalElementCountByLicence = (licence) => {
        const totalElementsByLicence = rawData.filter(item => item.licence === licence);
        return totalElementsByLicence.length;
    };



    // filtre les cartes (licences) par nombre de figurines possédées (décroissant)
    const sortedLicences = licences.sort((a, b) => {
        const countA = getLicenseCount(a);
        const countB = getLicenseCount(b);
        return countB - countA; // Tri décroissant
    });


    return (
        <>
            <div className="filtres">
                <div className='nb-fig-total'>{collectionElementCount} <span>figurines <br />possédées</span></div>
                <div className="separateur"></div>
                <div className='nb-fig-total'>{totalLicenseCount} <span>licences</span></div>
            </div>
            

            <div className={`cards-container card-grid-medium ${itemCount <= 5 ? 'uncomplete-row' : ''}`}>
                {sortedLicences.map(licence => (
                    <CollectionLicenceCard
                        key={licence}
                        licence={licence}
                        logoLicence={figurinesCollectionData.find(item => item.licence === licence).logo_licence}
                        elementCollectionCount={getLicenseCount(licence, figurinesCollectionData)}
                        totalElementCount={getTotalElementCountByLicence(licence)}
                    />
                ))}
            </div>
        </>
    );
}

export default CollectionLicencesCardsList