import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';
import CollectionFigurineCardsList from '../components/CollectionFigurineCardsList';
import BannerLicence from '../components/BannerLicence';
import { useParams } from "react-router-dom";
import function_licenceColorBackground from "../utils/function_licenceColorBackground";
import config_json from '../utils/config_json';


function CollectionLicencePage() {
    const [donnees, setDonnees] = useState([]);
    const { licence: slugLicence } = useParams();



    useEffect(() => {
        fetch(config_json.jsonLicencesURL)
            .then(response => response.json())
            .then(data => {
                setDonnees(data.find(item => item.slug_licence === slugLicence))
            })
            .catch(error => console.log(error));
    }, [slugLicence]);


    const licence = donnees.licence;
    const logoLicence = donnees.logo_licence;
    const couleurLicence = donnees.couleur_licence;
    const categoryName = "Ma Collection"
    const slugCategory = "ma-collection"

    function_licenceColorBackground(couleurLicence);


    return (
        <>
            <Title title={slugCategory} />

            <BannerLicence licence={licence} logoLicence={logoLicence} couleurLicence={couleurLicence} slugLicence={slugLicence}/>

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} licence={licence} slugLicence={slugLicence} />

            <section className='ma-collection-page'>
                <CollectionFigurineCardsList slugLicence={slugLicence}  />
            </section>
        </>
    );
};

export default CollectionLicencePage;