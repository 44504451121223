import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function SpecialEditionsStickerCard(props) {

    const [isLoaded, setIsLoaded] = useState(false);

    // chargement image placeholder le temps du chargement de l'image de la figurine
    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    // crée le slug et le passe en paramètre de la route, ainsi que l'id de la figurine pour pouvoir l'afficher sur la page de détail
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/editions-speciales/${props.edition_slug}`, { state: { edition: props.edition } });
    };




    return (
        <> {!isLoaded && <div><img src="https://thomak.xyz/pop-collection/assets/images/figurineCard-placeholder.svg" alt="" /></div>}

            <div className="figurineCard"
                style={{ display: isLoaded ? "block" : "none" }}
                onClick={props.notAvailable  ? null : handleClick}>
                <div className="card">
                    <div className="card-content">
                        <div className="col-nom">
                            <div className='nom'>{props.edition.toUpperCase()}</div>
                            <div className="nom-BG nonSelectionnable">{props.edition.toUpperCase()}</div>
                        </div>
                        <div className="card-image">
                            <div className="image-container">
                                <img src={props.image}
                                    alt={props.edition}
                                    onLoad={handleImageLoad}
                                />
                            </div>
                        </div>

                        {/* <div className="button button_secondary" >Voir toutes les figurines <br />{props.edition}</div> */}


                        {props.notAvailable ? <div className="out-of-order">En cours de construction</div> :
                            <div className="cta">Voir toutes les figurines</div>}

                    </div>

                    <div className="card-bg">
                        <img src="https://thomak.xyz/pop-collection/assets/images/card.svg" alt="" onLoad={handleImageLoad} />
                    </div>
                </div>
            </div >

        </>

    );


}


export default SpecialEditionsStickerCard;