import React, { useEffect, useState } from "react";
import SpecialEditionFigurineCardsList from '../components/SpecialEditionFigurineCardsList';
import Breadcrumb from '../components/Breadcrumb';
import Title from '../components/Title';
import { useParams } from "react-router-dom";
import config_json from '../utils/config_json';



function SpecialEditionPageDetail() {

    const [donnees, setDonnees] = useState([]);
    const { edition: slugEdition } = useParams();


    useEffect(() => {
        fetch(config_json.jsonEdSpeURL)
            .then(response => response.json())
            .then(data => {
                setDonnees(data.find(item => item.edition_slug === slugEdition))
                
            })
            .catch(error => console.log(error));
    }, [slugEdition]);

    const edition = donnees.edition;
    const categoryName = "Editions Speciales"
    const slugCategory = "editions-speciales"


    return (
        <>

            <Title title={slugCategory} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} edition={edition} slugEdition={slugEdition} />


            <section>
                <SpecialEditionFigurineCardsList slugEdition={slugEdition} edition={edition} />

            </section>
        </>
    );
};

export default SpecialEditionPageDetail;
