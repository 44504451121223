import React from 'react';

const UserScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Vos informations</div>
                <div className="pseudo">BomberX</div>
                <div className="line">
                    <div className="picto"><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-add-active.svg" alt="" /><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-share.svg" alt="" /></div>
                    <div className="line-content">
                        <div className="line-content-text">Partagez votre collection :</div>
                        <div className="line-content-link">www.pop-collection.com/BomberX/collection</div>
                    </div>
                </div>
                <div className="line">
                    <div className="picto"><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-fav-active.svg" alt="" /><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-share.svg" alt="" /></div>
                    <div className="line-content">
                        <div className="line-content-text">Partagez votre liste de souhaits :</div>
                        <div className="line-content-link">www.pop-collection.com/BomberX/wishlist</div>
                    </div>
                </div>
                <div className="line">
                    <div className="picto"><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-password-check.svg" alt="" /></div>
                    <div className="line-content">
                        <div className="line-content-text">Changer de  mot de passe</div>
                    </div>
                </div>
                <div className="line">
                    <div className="picto" onClick={() => handleScreenChange('deleteAccount')}><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-trash.svg" alt="" /></div>

                    <div className="line-content">
                        <div className="line-content-text">Supprimer mon compte</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserScreen;
