const config_FontSize = {
    // Page product (nbre caractères)
    mediumProductNameLength: 35,
    longProductNameLength: 45,

    // Page Licence (nbre caractères)
    mediumNameLength: 25,
    longNameLength: 30,
    veryLongNameLength: 40
}

export default config_FontSize;
