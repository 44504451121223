

function WIPmessage() {

    return (
        <>
            <div className="big-window">
                <div className="big-window-col1">
                    <div className="big-window-col1-content">
                        <div className="picto"><img src="https://thomak.xyz/pop-collection/assets/images/wip/picto-wip.png" alt="" /></div>
                        <div className="big-window-title wip">
                            Page en cours de construction
                        </div>
                        <div>Désolé pour le dérangement.</div>
                    </div>
                </div>
                <div className="big-window-col2 gradient_wip">
                    <div className="big-window-col2-image">
                        <img src="https://thomak.xyz/pop-collection/assets/images/wip/perso-wip.png" alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WIPmessage