import React from 'react'
import { Link } from 'react-router-dom'


function Title(props) {

    return (
            <Link to={`/${props.title}`} className="title">
                <img src={`https://thomak.xyz/pop-collection/assets/images/titles/title-${props.title}.png`} alt="" />
                <div className="title-BG"></div>
            </Link>
    )
}

export default Title