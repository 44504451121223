import React from 'react';

const DeleteAccountScreen = ({ handleScreenChange }) => {
    return (
        <>
            <div className="big-window-col1-content">
                <div className="big-window-title">Supprimer votre compte Pop Collection ?</div>
                <div className="callout">
                    <div className="picto"> <img src="https://thomak.xyz/pop-collection/assets/images/account/picto-warning.png" alt="" /></div>
                    <div>Toutes les données liées au compte seront supprimées définitivement.</div>
                </div>
                <span>Saisissez l'adresse email lié au compte <strong>Pop Collection</strong> pour valider la suppression définitive de votre compte</span>
                <form action="">
                    <input type="email" placeholder="Adresse email" />
                </form>                <div className="row">
                    <div className="button button_tertiary" onClick={() => handleScreenChange('deleteAccountValidation')}  >Supprimer mon compte</div>
                    <div className="button button_primary" onClick={() => handleScreenChange('user')}  >Annuler la suppression</div>
                </div>
            </div>
        </>
    );
};

export default DeleteAccountScreen;
