import React from 'react'
import { useNavigate } from 'react-router-dom'
import { function_createSlug } from "../utils/function_createSlug";

function CollectionLicenceCard(props) {



    const slugLicence = function_createSlug(props.licence);

    // créer le slug et le passe en paramètre de la route, ainsi que l'id de la figurine pour pouvoir l'afficher sur la page de détail
    const navigate = useNavigate();

    // Si la collection est vide, on affiche la wishlist
    const handleClick = () => {
        if (props.elementCollectionCount === 0) {
            navigate(`/ma-collection/${slugLicence}/wishlist`, { state: { licence: props.licence } });
        } else {
            navigate(`/ma-collection/${slugLicence}/collection`, { state: { licence: props.licence } });
        }
    };



    // infos pour les cercles de progression SVG
    const size = 100;
    const percentageCollection = props.elementCollectionCount / props.totalElementCount;


    return (
        <>
            <div className="collection-licence-card" onClick={handleClick}>

                {/* todo -  CSS suppr code "card" (voir si il es utilisé ailleurs) */}
                <div className="collection-licence-card-content">
                    <div className="logo-licence">
                        <img src={props.logoLicence + "-small.png"} alt="" />
                    </div>
                    <div className="figurine-counter" >
                        <div className="figurine-counter-circle">
                            <svg viewBox={`0 0 ${size} ${size}`} width="130px" height="130px">
                                {/* cercle total */}
                                <circle
                                    cx={size / 2}
                                    cy={size / 2}
                                    r={size * 0.4}
                                    fill="none"
                                    stroke="#C6EDFD"
                                    strokeWidth="10"
                                />

                                {/* cercle des figurines dans la collection */}
                                <path
                                    fill="none"
                                    stroke="#1bb8f9"
                                    strokeWidth="10"
                                    strokeLinecap="round"
                                    d={`M ${size / 2} ${size / 2 - size * 0.4}
                                    A ${size * 0.4} ${size * 0.4} 0 ${percentageCollection > 0.5 ? "1" : "0"} 1
                                    ${size / 2 + size * 0.4 * Math.sin(2 * Math.PI * percentageCollection)} 
                                    ${size / 2 - size * 0.4 * Math.cos(2 * Math.PI * percentageCollection)}`}
                                />
                            </svg>
                        </div>
                        <div className="figurine-counter-label">
                            <div>{props.elementCollectionCount}/{props.totalElementCount}</div>
                            <div><span>figurines possédeés</span></div>
                        </div>


                    </div>
                    <div className="button button_primary">Voir la collection</div>
                </div>

                <div className="card-bg">
                    <img src="https://thomak.xyz/pop-collection/assets/images/card-collection.svg" alt="" />
                </div>
            </div >



        </>

    )
}

export default CollectionLicenceCard