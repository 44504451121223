import React from "react";
import FigurineCardsListDebug from '../components/FigurineCardsListDebug';


// DEBUG  Fichier debug


function Debug() {

    const categoryName = "Debug"
    const slugCategory = "debug"

    return (
        <>
            <FigurineCardsListDebug categoryName={categoryName} slugCategory={slugCategory} />
        </>
    );
};

export default Debug;