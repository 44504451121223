import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import function_licenceColorBackground from "../utils/function_licenceColorBackground";


function Navbar({ toggleMenu }) {


    // TODO : code à revoir : si on remplace les elements currentPage par la classe "active" de NavLink, il faut supprimer le useLocation
    // pour déterminer la page courante et ajouter la classe lien current-page au lien
    const location = useLocation();
    const currentPage = location.pathname;


    function_licenceColorBackground();
    const closeMenu = () => {
        toggleMenu();
    };


    return (
        <nav>
            <ul>

                <li>
                    <NavLink to="/" onClick={closeMenu} className={currentPage === '/' ? 'lien-menu current-page' : 'lien-menu'}>Accueil</NavLink>
                </li>


                {/* <li>
                        <NavLink exact to="/"><div className='picto-house'></div></NavLink>
                        <div className="picto-house-hover preload"></div>
                    </li> */}


                <li>
                    <NavLink to="/licences" onClick={closeMenu} className={currentPage === '/licences' ? 'lien-menu current-page' : 'lien-menu'}>Licences</NavLink>
                </li>
                <li>
                    <NavLink to="/nouveautes" onClick={closeMenu} className={currentPage === '/nouveautes' ? 'lien-menu current-page' : 'lien-menu'}>Nouveautés</NavLink>
                </li>
                {/* <li>
                                <NavLink to="/univers" onClick={closeMenu} className={currentPage === '/univers' ? 'lien-menu current-page' : 'lien-menu'}>Univers 🚧</NavLink>
                            </li> */}
                <li>
                    <NavLink to="/editions-speciales" onClick={closeMenu} className={currentPage === '/editions-speciales' ? 'lien-menu current-page' : 'lien-menu'}>Editions Spéciales</NavLink>
                </li>
                <li>
                    <NavLink to="/mon-compte" onClick={closeMenu} className={currentPage === '/mon-compte' ? 'lien-menu current-page' : 'lien-menu'}>Mon compte</NavLink>
                </li>
                <li>
                    <NavLink to="/ma-collection" onClick={closeMenu} className={`button button-header ${currentPage === '/ma-collection' ? 'current-page-button' : ''}`}>
                        {currentPage === '/ma-collection' ? (
                            <div className='current-page'>Ma Collection</div>
                        ) : (
                            <div>Ma collection</div>
                        )}
                    </NavLink>
                </li>
                {/* <li>
                                <NavLink to="/mon-compte" className={currentPage === '/mon-compte' ? 'lien-menu current-page' : 'lien-menu'}>
                                    <div className='bigHead-avatar'>
                                        <BigHead {...getRandomOptions()} />
                                    </div>
                                </NavLink>
                            </li> */}

            </ul>
        </nav>
    );
};

export default Navbar;