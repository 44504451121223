import React from 'react';
import Navbar from './Navbar';

function MenuBurger({ isMenuOpen, toggleMenu }) {


    return (
        <div className={`menu-container ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="close-menu-button" onClick={toggleMenu}>
                <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-close-menu-white.svg" alt="" />
            </div>
            <Navbar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </div>
    );
}

export default MenuBurger;
