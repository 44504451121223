import React, { useEffect, useState } from 'react';
import Hero from '../components/Hero';
import LicencesCardsList from '../components/LicencesCardsList';
import { Link } from "react-router-dom";
import FigurineCard from '../components/FigurineCard';
import config_json from '../utils/config_json';
import { isDateGreaterThanToday } from '../utils/function_isDateGreaterThanToday';


function HomePage() {
    const [filteredFigurines, setFilteredFigurines] = useState([]);


    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {

                const sortedData = data.sort((a, b) =>
                    new Date(b.date_sortie_tri) - new Date(a.date_sortie_tri)
                );

                // Ne récupérer que les 8 plus récentes
                const latestData = sortedData.slice(0, 8);

                setFilteredFigurines(latestData);

            })
            .catch(error => {
                console.log(error);
            })

    }, []);




    return (
        <div className='homepage'>

            <div className="blob"></div>

            <Hero />

            <section>
                <div className='colored-bg'></div>
                <div className="titre-section">
                    <img src='https://thomak.xyz/pop-collection/assets/images/layout/titre-section-licences-populaires.png' alt="" />
                </div>
                <LicencesCardsList elementDisplayLimit={7} />
                <Link to="/licences" className="button button_secondary">Voir toutes les licences</Link>
            </section>



            <section>
                <div className="titre-section">
                    <img src='https://thomak.xyz/pop-collection/assets/images/layout/titre-section-nouveautes.png' alt="" />
                </div>

                <div className="cards-container card-grid-big">
                    {/* Tri par ref puis par date */}

                    {filteredFigurines.map((item) => (
                        <FigurineCard
                            key={item.id}
                            id={item.id}
                            id_funko={item.id_funko}
                            ref_funko={item.ref}

                            nom={item.nom}
                            nom_ed={item.nom_ed}
                            licence={item.licence}
                            couleur_licence={item.couleur_licence}

                            image={item.images.img_url1}
                            imageHover={item.images.img_url3}

                            editions={item.editions}
                            ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                            ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                            ed_spe3={item.editions ? item.editions.ed_spe3 : null}
                            variant_form={item.variant_form}

                            date_sortie={item.date_sortie_aff}

                            date_sortie_tri={item.date_sortie_tri}

                            slug_figurine={item.slug_figurine}
                            slug_licence={item.slug_licence}

                            categoryName={"Nouveautés"}
                            future={isDateGreaterThanToday(item.date_sortie_tri)}
                        />
                    ))}
                </div>



                <Link to="/nouveautes" className="button button_secondary">Voir toutes les nouveautés</Link>
            </section>




            {/*   <section>
                <div className="titre-section">
                    <img src='https://thomak.xyz/pop-collection/assets/images/layout/titre-section-univers.png' alt="" />
                </div>
                <div className="home-banner">
                    <div className="banner square-mask"><img src='https://thomak.xyz/pop-collection/assets/images/univers/card-univers-films.png' alt="" /></div>
                    <div className="banner square-mask"><img src='https://thomak.xyz/pop-collection/assets/images/univers/card-univers-anime.png' alt="" /></div>
                    <div className="banner square-mask"><img src='https://thomak.xyz/pop-collection/assets/images/univers/card-univers-jeux-video.png' alt="" /></div>
                    <div className="banner square-mask"><img src='https://thomak.xyz/pop-collection/assets/images/univers/card-univers-series-tv.png' alt="" /></div>
                </div>
                <Link to="/univers" className="button button_secondary">Découvrir tous les univers</Link>
            </section> */}

        </div>
    );
};

export default HomePage;