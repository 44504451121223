import React from "react";
import SpecialEditionsStickerCardsList from '../components/SpecialEditionsStickerCardsList';
import Breadcrumb from '../components/Breadcrumb';
import Title from '../components/Title';


function SpecialEditionsPage() {

    const categoryName = "Editions Spéciales"
    const slugCategory = "editions-speciales"

    return (
        <>
            <Title title={slugCategory} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />
            <section>
                <SpecialEditionsStickerCardsList />
            </section>
        </>
    );
};

export default SpecialEditionsPage;
