import React from 'react';

function Footer() {

    return (
        <>
            <div className="footer">
                <div className="footer-container">

                    <div>© 2023 Pop Collection</div>


                </div>

            </div >
        </>
    );
};

export default Footer;