import React from "react";
import { useNavigate } from "react-router-dom";
import { function_createSlug } from "../utils/function_createSlug";
import { function_edSpeReplacementValues } from "../utils/function_edSpeReplacementValues";
import Select from 'react-select'


function FilterButtons(props) {
  const { handleSelect, handleResetFilters, itemCount, addCount, favCount, licence, slugLicence, selectedValue, options, categoryName } = props;
  // const [imagesLoaded, setImagesLoaded] = useState(false);

  const navigate = useNavigate();


  const selectStyles = {
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      backgroundImage: `url('https://thomak.xyz/pop-collection/assets/images/stickers/50px/sticker-50-${function_edSpeReplacementValues(function_createSlug(data.value))}.png')`,
      backgroundColor: isSelected ? '#1DDBFC' : 'transparent',
      color: 'black',
      ':hover': {
        backgroundColor: isFocused ? '#fee9a4' : 'transparent',
        color: isFocused ? 'black' : 'transparent',
      },
    })
  };



  //TODO : revoir le code lorsque la structure sera modifiée (page wishlist et page collection)
  const handleClickCollection = () => {
    navigate(`/ma-collection/${props.slugLicence}/collection`, { state: { slugLicence: slugLicence } });
  };
  const handleClickWishlist = () => {
    navigate(`/ma-collection/${props.slugLicence}/wishlist`, { state: { slugLicence: slugLicence } });
  };


  return (
    <>
      <div className={`filtres ${categoryName === "Licences" ? "dir-vertical" : ""}`}>
        <div className="nb-fig">
          <div className="nb-fig-total">{itemCount} <br /><span>{itemCount === 1 ? 'figurine' : 'figurines'}</span></div>
          {(addCount > 0 || favCount > 0) && <div className="nb-fig-collec">
            {addCount > 0 &&
              <div className="tooltip-container">
                <div className="nb-fig-collection" onClick={handleClickCollection}>
                  <span className='tooltip'>Voir les figurines {licence} de ma collection</span>
                  <div>{addCount}</div>
                  <div><img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-add-active.svg" alt="" /></div>
                </div>
              </div>
            }

            {favCount > 0 &&
              <div className="tooltip-container">
                <div className="nb-fig-wishlist" onClick={handleClickWishlist}>
                  <span className='tooltip'>Voir les figurines {licence} de ma liste de souhait</span>
                  <div>{favCount}</div>
                  <div>
                    <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-fav-active.svg" alt="" />
                  </div>
                </div>
              </div>
            }
          </div>}
        </div>


        <div className="separateur"></div>


        <div className="sort-by-edition">
          <div>Filtrer par édition spéciale</div>

          <div className="row">
            <Select
              options={options}
              value={selectedValue}
              onChange={handleSelect}
              placeholder={"Choisissez une édition"}

              {...props}// permet d'utiliser des classes
              className="react-select-container"
              classNamePrefix="react-select"
              styles={selectStyles}

            />

            <div className="cancel-filter-button tooltip-container" onClick={handleResetFilters}>
              <div className="cancel-filter-button-hover preload"></div>
              <span className='tooltip'>Afficher toutes les figurines</span>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default FilterButtons;
