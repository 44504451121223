import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import SearchBar from './Search';
import function_licenceColorBackground from "../utils/function_licenceColorBackground";
import MenuBurger from './MenuBurger';
import Navbar from './Navbar';

//Avatars
// import { BigHead } from "@bigheads/core";
// import { getRandomOptions } from "../utils/bigheads";


function Header() {

    function_licenceColorBackground();

    // menu burger
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    console.log("isSearchBarOpen(header) : ", isSearchBarOpen)

    // searchbar
    const toggleSearchBar = () => {
        setIsSearchBarOpen(!isSearchBarOpen);
    }



    return (
        <>
            <div className="header">
                <div className={`header-container ${isSearchBarOpen ? 'adapt-background' : ''}`}>
                    <div className="header-container-content">
                        <div className="logo">
                            <NavLink exact to="/"><img src="https://thomak.xyz/pop-collection/assets/images/header/logo-pop-collection.png" alt="" /></NavLink>
                        </div>
                        <div className="burger-button" onClick={toggleMenu}>
                            <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-burger.svg" alt="" />
                        </div>
                        <MenuBurger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                        <div className='navbar-desktop'>
                            <Navbar toggleMenu={toggleMenu} />
                        </div>

                        <div className='search-button' onClick={toggleSearchBar}>
                            <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-search.svg" alt="" />
                        </div>

                    </div>
                    <SearchBar isSearchBarOpen={isSearchBarOpen} />
                </div>

            </div >
        </>
    );


};

export default Header;