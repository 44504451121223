import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 320,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible &&
        <div
          onClick={scrollToTop} className='scrollTopButton'>
          <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-arrow-up.svg" alt="" />
        </div>
      }
    </div>
  );
};

export default ScrollToTopButton;
