import React from 'react'
import { useNavigate } from 'react-router-dom'


function LicenceCard(props) {

    // TODO : remplacer useNavigate par Link ?
    // useNavigate : créer le slug et le passe en paramètre de la route, ainsi que l'id (et éventuellement d'autres infos)
    const navigate = useNavigate();

    // Dirige vers la page de la licence sélectionnée
    const handleClickLicence = () => {
        navigate(`/licences/${props.slug_licence}`, { state: { licence: props.licence, page: "licencePage" } });
    };

    // Diregige vers la page de la licence sélectionnée (Nouveautés Only)
    const handleClickNewReleases = () => {
        navigate(`/nouveautes/${props.slug_licence}`, { state: { licence: props.licence, page: "newReleasesLicence" } });
    };

    return (
        <>
            <div className="licence-card" onClick={props.categoryName === "Nouveautés" ? handleClickNewReleases : handleClickLicence}>
                <div className="card">
                    <div className="logo-licence">
                        <img src={props.logo_licence + "-small.png"} alt={props.licence} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default LicenceCard