import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Roads from "./utils/Roads";
import UnderConstructionBanner from './utils/UnderConstructionBanner';

console.log("V44-2024-1502")

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <div className="app-container">
          <UnderConstructionBanner />
          <div className="main">
            <Roads />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default App;