import React, { useEffect, useState } from 'react'
import LicenceCard from './LicenceCard'
import config_json from '../utils/config_json';


function LicencesCardsList({ elementDisplayLimit, categoryName }) {
    const [donnees, setDonnees] = useState([]);
    const [itemCount, setItemCount] = useState([]);
    const [favoriteLicences, setFavoriteLicences] = useState([]);
    const [favLicencesIsActive, setFavLicencesIsActive] = useState(() => {
        // Initialiser l'état du toggle à partir du localStorage
        return JSON.parse(localStorage.getItem('favLicencesIsActive')) || false;
    });


    const toggleButton = () => {
        setFavLicencesIsActive(!favLicencesIsActive);
        // Sauvegarder le nouvel état dans le localStorage
        localStorage.setItem('favLicencesIsActive', JSON.stringify(!favLicencesIsActive));
    };


    // défini la valeur de recherche des X derniers mois
    const period = 12


    useEffect(() => {
        // Si c'est la page Nouveautés, recherche des figurines dont la date de sortie est dans les X derniers mois
        if (categoryName === "Nouveautés") {
            fetch(config_json.jsonFigurinesURL)
                .then(response => response.json())
                .then(data => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const xMonthsAgo = new Date(today);
                    xMonthsAgo.setMonth(today.getMonth() - period);

                    const recentLicenceNames = data.filter(item => {
                        const itemDate = new Date(item.date_sortie_tri);
                        return itemDate >= xMonthsAgo && itemDate <= today;
                    }).map(item => item.licence);

                    const filteredData = data.filter(item => item.add_state === true || item.fav_state);
                    const uniqueLicences = filteredData.reduce((acc, currentItem) => {
                        if (!acc.includes(currentItem.licence)) {
                            acc.push(currentItem.licence);
                        }
                        return acc;
                    }, []);


                    setFavoriteLicences(uniqueLicences);

                    // Récuperer la liste des licences qui correspondent aux nouveautées
                    fetch(config_json.jsonLicencesURL)
                        .then(response => response.json())
                        .then(data => {
                            const filteredNewLicences = data.filter(item => recentLicenceNames.includes(item.licence));
                            setDonnees(filteredNewLicences);
                            setItemCount(filteredNewLicences.length);


                        })
                        .catch(error => console.log(error));
                })
                .catch(error => console.log(error));
        } else {
            // ... sinon, récupère TOUTES les licences dans "donnees"
            fetch(config_json.jsonLicencesURL)
                .then(response => response.json())
                .then(data => {
                    setDonnees(data);
                    setItemCount(data.length);
                })
                .catch(error => console.log(error));
        }
    }, [categoryName]);


    let filteredLicences;

    // Si c'est la page "Nouveautés", prendre en compte les licences favorites, sinon ne pas prendre en compte (sinon aucune licence ne s'affiche sur la page "Licences")
    if (categoryName === "Nouveautés") {
        filteredLicences = favLicencesIsActive ? donnees.filter(item => favoriteLicences.includes(item.licence)) : donnees;
    } else {
        filteredLicences = donnees;
    }

    // Définir le nombre maximum de licences à afficher. Utilise la valeur par défaut si aucune limite n'est spécifiée dans le props limitDisplay
    const itemsDisplay = elementDisplayLimit ? filteredLicences.slice(0, elementDisplayLimit) : filteredLicences;



    return (
        <>
            {categoryName === "Nouveautés" && (
                <div className="recent-figurines-title-container">
                    <div className="recent-figurines-title">Licences avec des sorties récentes et à venir</div>

                    {/* Toggle licences préférées (nouveautées) */}
                    <div className="switch-row">
                        <label class="switch">
                            <input
                                type="checkbox"
                                checked={favLicencesIsActive}
                                onChange={toggleButton}
                            />
                            <span class="slider"></span>
                        </label>
                        <div>Licences favorites uniquement</div>
                    </div>

                </div>
            )}

            <div className={`cards-container card-grid-small ${itemCount < 5 ? 'uncomplete-row' : ''}`}>
                {itemsDisplay.map((item) => (
                    <LicenceCard
                        key={item.id}
                        id={item.id}

                        licence={item.licence}
                        logo_licence={item.logo_licence}
                        couleur_licence={item.couleur_licence}
                        slug_licence={item.slug_licence}

                        categoryName={categoryName}
                    />
                ))}
            </div >
        </>
    );
}

export default LicencesCardsList