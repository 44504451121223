import React, { useEffect, useRef, useState } from 'react';
import searchData from '../assets/pop_collection_bdd_figurines.json';
import { function_createSlug } from '../utils/function_createSlug';
import { useNavigate } from 'react-router-dom';
import { function_edSpeReplacementValues } from '../utils/function_edSpeReplacementValues';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({ isSearchBarOpen }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [licenceResults, setLicenceResults] = useState([]);
    const [figurineResults, setFigurineResults] = useState([]);
    const [displayResults, setDisplayResults] = useState(false);
    const [displayNoResults, setDisplayNoResults] = useState(false);


    const searchResultsRef = useRef(null);
    const searchFieldRef = useRef(null);

    const placeholderValue = "Rechercher une figurine, une licence...";


    const handleSearchInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);


        // Vérifier si le champ de recherche est vide pour réinitialiser les résultats de recherche
        if (value === '') {
            // Réinitialiser les résultats de recherche à des listes vides
            setLicenceResults([]);
            setFigurineResults([]);
            setDisplayResults(false);
            setDisplayNoResults(false);

        } else if (value.length >= 3) {
            // Masquer les résultats de recherche si la longueur du texte saisie est inférieure à 3 caractères
            // Appeler la fonction de recherche avec un délai pour éviter les appels excessifs
            setTimeout(() => {
                searchDatabase(value);
            }, 400);
            setDisplayResults(true);

        }
        else if (value.length >= 3 && displayNoResults === true) {
            setTimeout(() => {
            }, 400);
            setDisplayResults(true);
            setDisplayNoResults(true);

        } else {
            setLicenceResults([]);
            setFigurineResults([]);
            setDisplayResults(false);
            setDisplayNoResults(false);

        }
    };

    const searchDatabase = async (query) => {
        try {
            // Convertir la chaîne de recherche en minuscules et supprimer les accents
            const normalizedQuery = query
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');

            const searchTerms = normalizedQuery.split(' ');


            // Filtrer les résultats de recherche pour les licences
            const filteredLicenceResults = searchData.filter((result, index, self) => {
                const normalizedLicence = result.licence
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');

                return searchTerms.every(term => normalizedLicence.includes(term)) && self.findIndex(r => r.licence === result.licence) === index;

            });

            // Mettre à jour les résultats de recherche pour les licences dans l'interface utilisateur
            setLicenceResults(filteredLicenceResults.slice(0, 5));

            // Filtrer les résultats de recherche pour les noms
            const filteredFigurineResults = searchData.filter(result => {
                const normalizedNom = result.nom
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');

                return normalizedNom.includes(normalizedQuery);
            });

            // Mettre à jour les résultats de recherche pour les noms dans l'interface utilisateur
            setFigurineResults(filteredFigurineResults.slice(0, 10));

            // Vérifier si les résultats de recherche sont vides et afficher un message approprié
            if (filteredLicenceResults.length === 0 && filteredFigurineResults.length === 0) {
                setDisplayNoResults(true);
            } else {
                setDisplayNoResults(false);
            }

        } catch (error) {
            console.error('Erreur lors de la recherche :', error);
        }
    };



    // liens vers les pages de licences et de figurines depuis les résultats de recherche
    const navigate = useNavigate();

    // Fonction pour générer le lien dynamique
    const generateLink = (categorie, result) => {
        const slugLicence = result.slug_licence;
        let link, content;

        // création du lien soit vers la licence, soit vers la figurine
        const handleClick = () => {
            navigate(link, { state: { id: result.id, licence: result.licence } });

            // Réinitialiser les résultats de recherche
            setLicenceResults([]);
            setFigurineResults([]);

            setDisplayResults(false);

            // Effacer le champ de recherche
            setSearchTerm('');
        };


        // Création des slugs pour les images des stickers
        const stickerElements = [];

        for (let i = 1; i <= 3; i++) {
            const edSpeValue = result.editions?.[`ed_spe${i}`];
            const edSpeValueReplacement = function_edSpeReplacementValues(edSpeValue);
            const slugValue = edSpeValueReplacement ? function_edSpeReplacementValues(function_createSlug(edSpeValueReplacement)) : '';

            if (slugValue) {
                const stickerElement = (
                    <div className="search-result-item-content-sticker-container" key={i}>
                        <img src={`https://thomak.xyz/pop-collection/assets/images/stickers/50px/sticker-50-${slugValue}.png`} alt="" />
                    </div>
                );
                stickerElements.push(stickerElement);
            }
        }

        if (categorie === 'licence') {
            link = `/licences/${slugLicence}`;

            content = (
                <div className="search-result-item licence">
                    <div className="search-result-item-content">
                        <div className="search-result-item-content-image-container">
                            <img src={result.logo_licence + "-small.png"} alt={result.licence} />
                        </div>
                    </div>
                </div >
            )


        } else if (categorie === 'figurine') {

            console.log("result: ", result)
            link = `/licences/${result.slug_licence}/${result.slug_figurine}`;
            content = (
                <div className="search-result-item figurine">
                    <div className="search-result-item-content">
                        <div className="search-result-item-content-col-left">
                            <div className="search-result-item-content-image">
                                <div className="search-result-item-content-image-container">
                                    <img src={result.images.img_url1} width="50px" alt={result.nom} />
                                </div>
                            </div>
                        </div>
                        <div className="search-result-item-content-col-right">
                            <div className="search-result-item-content-detail">
                                <div className="search-result-item-content-detail-licence" style={{ color: result.couleur_licence }}>{result.licence}</div>
                                <div className="line">
                                    <div className="search-result-item-content-detail-name">{result.nom}</div>
                                    {/* <div className="search-result-item-content-detail-ref"> #{result.ref}</div> */}
                                    <div className="search-result-item-content-detail-ref"> {result.ref === "Pack" ? result.ref : "#" + result.ref}</div>
                                </div>
                            </div>
                            <div className='search-result-item-content-stickers-row'> {stickerElements}</div>
                        </div>
                    </div>
                </div >
            )
        }


        return (
            <>
                <a href={link} onClick={handleClick}>
                    {content}
                </a>
            </>

        );
    };


    // ferme les résultats de recherche si on clic en dehors du champs de recherche
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchResultsRef.current &&
                !searchResultsRef.current.contains(event.target)
                && searchFieldRef.current &&
                !searchFieldRef.current.contains(event.target)
            ) {
                setDisplayResults(false);
                setSearchTerm('');
            }
        };

        document.addEventListener('click', handleClickOutside);

        //supprime le gestionnaire d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    return (
        <>
            <div className={`search-bar ${isSearchBarOpen ? 'searchbar-open' : ''}`} ref={searchFieldRef} >

                <FontAwesomeIcon icon={faMagnifyingGlass} className='input-search-icon' />
                <input
                    type="search"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    placeholder={placeholderValue}
                    onFocus={(e) => {
                        e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                        e.target.placeholder = placeholderValue;
                    }}
                    className="search-input"
                />
            </div >
            {/* Afficher les résultats de recherche pour les licences */}
            {displayResults && (licenceResults.length > 0 || figurineResults.length > 0 || displayNoResults) &&
                <div ref={searchResultsRef}>
                    {/*  <div className={`search-results ${displayResults ? 'results-expand-animation' : ''}`}> */}
                    <div className='search-results results-expand-animation'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='search-panel-icon' />
                        <div>
                            {licenceResults.length > 0 && (
                                <>
                                    {licenceResults.slice(0, 3).map((result) => (
                                        <div key={result.id}>
                                            {generateLink("licence", result)}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {/* Afficher les résultats de recherche pour les figurines */}
                        {figurineResults.length > 0 && (
                            <>
                                {licenceResults.length > 0 &&
                                    <div className="search-results-separator"></div>}
                                {figurineResults.slice(0, 8).map((result) => (
                                    <div key={result.id}>
                                        {generateLink("figurine", result)}
                                    </div>
                                ))}
                            </>
                        )}
                        {/* Afficher un message indiquant qu'il n'y a aucun résultat de recherche */}
                        {displayNoResults && figurineResults.length === 0 && (
                            <div className="search-result-item licence no-results">
                                <div className="search-result-item-content">
                                    <div className="search-result-item-content-col-left">
                                        <div className="search-result-item-content-image">
                                            <div className="search-result-item-content-image-container">
                                                <img src="https://thomak.xyz/pop-collection/assets/images/header/img-no-result.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-result-item-content-col-right">
                                        Aucune figurine ou licence ne correspond à <strong>"{searchTerm}"</strong>
                                    </div>
                                </div>
                            </div >
                        )}
                        <div />
                    </div >
                </div >
            }
        </>
    );
};


export default SearchBar;
