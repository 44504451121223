import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import config_json from '../utils/config_json';

const Breadcrumb = (props) => {
  const [currentLicence, setCurrentLicence] = useState([]);
  const [currentFigurine, setCurrentFigurine] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const { licence: slugLicence, figurine: slugFigurine } = useParams();
  const [isOpen, setIsOpen] = useState(false);


  const location = useLocation();
  const currentPageLink = location.pathname;


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    fetch(config_json.jsonFigurinesURL)
      .then(response => response.json())
      .then(data => {
        const rechercheLicence = data.find(item => item.slug_licence === slugLicence);
        const rechercheFigurine = data.find(item => item.slug_figurine === slugFigurine);

        if (slugLicence && slugFigurine) {
          setCurrentLicence(rechercheLicence.licence)
          setCurrentFigurine(rechercheFigurine.nom);
        } else if (slugLicence && !slugFigurine) {
          setCurrentLicence(rechercheLicence.licence)
          setCurrentFigurine(undefined);
        } else {
          setCurrentLicence(undefined)
          setCurrentFigurine(undefined);
        }

        setDataLoaded(true); // Indique que les données sont chargées avec succès avant d'afficher les images (arrow)
      })
      .catch(error => console.log(error));
  }, [slugLicence, slugFigurine]);



  function breadcrumbElement(condition, label, link) {
    if (condition) {
      const isCurrentPage = currentPageLink === link;
      const className = isCurrentPage ? 'breadcrumb-element current-page' : 'breadcrumb-element';
      return (
        <div className={className}>
          {condition && (
            <img className="arrow" src="https://thomak.xyz/pop-collection/assets/images/breadcrumb-arrow.svg" alt="" />
          )}
          {isCurrentPage ? (
            //TODO  text-overflow: ellipsis;
            <div className="lien current-page">
              <div className='bloc-text'>{label}</div>
            </div>
          ) : (
            <Link to={`${link}`} className="lien">{label}</Link>
          )}
        </div>
      );
    }
    return null;
  }



  return (
    <>
      <div className="breadcrumb">
        {/* 1er niveau - Accueil */}
        <div className="element lien">
          <Link to={"/"}>Accueil</Link>
        </div>
        {/* 2e niveau - catégorie (Licences / Editions Speciales) */}
        {dataLoaded && breadcrumbElement(props.currentCategory !== undefined, props.currentCategory, `/${props.slugCategory}`)}

        {/* 3e niveau - licence (détail) / Ed Spe (détail) */}
        {dataLoaded && props.slugCategory && props.slugCategory !== "ma-collection" && breadcrumbElement(props.licence !== undefined, props.licence, `/${props.slugCategory}/${props.slugLicence}`)}
        {dataLoaded && currentPageLink === `/${props.slugCategory}/${props.slugLicence}/collection` && breadcrumbElement(props.licence !== undefined, `${props.licence} (collection)`, `/${props.slugCategory}/${props.slugLicence}/collection`)}
        {dataLoaded && currentPageLink === `/${props.slugCategory}/${props.slugLicence}/wishlist` && breadcrumbElement(props.licence !== undefined, `${props.licence} (wishlist)`, `/${props.slugCategory}/${props.slugLicence}/wishlist`)}
        {dataLoaded && props.edition && breadcrumbElement(props.edition !== undefined, props.edition, `/${props.slugCategory}/${props.slugEdition}`)}

        {/* 4e niveau - figurine / Ed Spe détail */}
        {dataLoaded && slugFigurine && breadcrumbElement(slugFigurine !== undefined, currentFigurine, `/${props.slugCategory}/${props.slugLicence}/${slugFigurine}`)}
      </div>


      {/* ------  version mobile (menu déroulant) ------ */}
      <div div className="dropdown-container" >
        <div className="dropdown">
          <div className={`dropdown-header ${isOpen ? 'menu-ouvert' : ''}`} onClick={toggleMenu}>
            <div className="dropdown-header-text">{dataLoaded && currentFigurine ? currentFigurine : (props.licence ? props.licence : (props.currentCategory ? props.currentCategory : null))}  </div>
          </div>


          {isOpen && (
            <ul>
              <li className="element lien">
                <Link to={"/"}>Accueil</Link>
              </li>


              {/* 2e niveau - catégorie (Licences / Editions Speciales) */}
              {dataLoaded && (
                <li>
                  {breadcrumbElement(props.currentCategory !== undefined, props.currentCategory, `/${props.slugCategory}`)}
                </li>
              )}


              {/* 3e niveau - licence (détail) / Ed Spe (détail) */}
              {dataLoaded && props.slugCategory && props.slugCategory !== "ma-collection" && (
                <li>
                  {breadcrumbElement(props.licence !== undefined, props.licence, `/${props.slugCategory}/${props.slugLicence}`)}
                </li>
              )}
              {dataLoaded && currentPageLink === `/${props.slugCategory}/${props.slugLicence}/collection` && (
                <li>
                  {breadcrumbElement(props.licence !== undefined, `${props.licence} (collection)`, `/${props.slugCategory}/${props.slugLicence}/collection`)}
                </li>
              )}
              {dataLoaded && currentPageLink === `/${props.slugCategory}/${props.slugLicence}/wishlist` && (
                <li>
                  {breadcrumbElement(props.licence !== undefined, `${props.licence} (wishlist)`, `/${props.slugCategory}/${props.slugLicence}/wishlist`)}
                </li>
              )}
              {dataLoaded && props.edition && (
                <li>
                  {breadcrumbElement(props.edition !== undefined, props.edition, `/${props.slugCategory}/${props.slugEdition}`)}
                </li>
              )}


              {/* 4e niveau - figurine / Ed Spe détail */}
              {dataLoaded && slugFigurine && (
                <li>
                  {breadcrumbElement(slugFigurine !== undefined, currentFigurine, `/${props.slugCategory}/${props.slugLicence}/${slugFigurine}`)}
                </li>
              )}

            </ul>
          )}
        </div>
      </div >
    </>
  );

}
export default Breadcrumb;

