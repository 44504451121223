import React from 'react';
// import UniversCardsList from '../components/UniversCardsList';
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';
import WIPmessage from '../utils/WIPmessage';

function UniversPage() {
    const categoryName = "Univers"
    const slugCategory = "univers"


    return (
        <>
            <Title title={slugCategory} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />


            <section className='univers-page'>
                {/* <UniversCardsList /> */}

                <WIPmessage />


            </section>
        </>
    );
};

export default UniversPage;