import React from "react";
import ReusableFigCardsListPage from '../pages/ReusableFigCardsListPage';


function LastReleasesPageDetail() {

    const categoryName = "Nouveautés"
    const slugCategory = "nouveautes"

    return (
        <>
            <ReusableFigCardsListPage categoryName={categoryName} slugCategory={slugCategory} />
        </>
    );
};

export default LastReleasesPageDetail;