import React, { useEffect, useState } from "react";
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';
import { function_createSlug } from '../utils/function_createSlug';
// import WIPmessage from "../utils/WIPmessage";
import ConnexionScreen from '../components/account/ConnexionScreen';
import CreateAccountScreen from '../components/account/CreateAccountScreen';
import UserScreen from '../components/account/UserScreen';
import ReinitPasswordScreen from "../components/account/ReinitPasswordScreen";
import ReinitPasswordScreenSendEmail from "../components/account/ReinitPasswordScreenSendEmail";
import DeleteAccountScreen from "../components/account/DeleteAccountScreen";
import DeleteAccountValidationScreen from "../components/account/DeleteAccountValidationScreen";

// import { useAppContext } from './AppContext';



const componentsMap = {
    user: {
        component: UserScreen,
        className: "gradient1",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-info.png",
    },
    connexion: {
        component: ConnexionScreen,
        className: "gradient2",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-connect.png",
    },
    createAccount: {
        component: CreateAccountScreen,
        className: "gradient3",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-create.png",
    },
    reinitPassword: {
        component: ReinitPasswordScreen,
        className: "gradient4",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-forget-password.png",
    },
    reinitPasswordSendEmail: {
        component: ReinitPasswordScreenSendEmail,
        className: "gradient4  ",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-forget-password.png",
    },
    deleteAccount: {
        component: DeleteAccountScreen,
        className: "gradient5",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-delete.png",
    },
    deleteAccountValidation: {
        component: DeleteAccountValidationScreen,
        className: "gradient5",
        image: "https://thomak.xyz/pop-collection/assets/images/account/account-figure-delete.png",
    },
};

function AccountPage() {
    const [currentScreen, setCurrentScreen] = useState('connexion');
    const [previousScreen, setPreviousScreen] = useState('connexion');
    const [previousScreenImage, setPreviousScreenImage] = useState('');


    const categoryName = "Mon compte"
    const slugCategory = "mon-compte"



    const handleScreenChange = (screen) => {
        setPreviousScreen(currentScreen); // Stocker l'écran précédent avant la mise à jour
        setCurrentScreen(screen);
    };


    // console.log(componentsMap[currentScreen])


    const CurrentScreenComponent = componentsMap[currentScreen]?.component;
    const currentScreenClass = componentsMap[currentScreen]?.className;
    const previousScreenClass = componentsMap[previousScreen]?.className;
    const currentScreenImage = componentsMap[currentScreen]?.image;



    useEffect(() => {
        const elementsToAnimate = ['.grad', '.big-window-col2-image'];
        if (currentScreenImage !== previousScreenImage) {

            elementsToAnimate.forEach((selector) => {
                const element = document.querySelector(selector);
                if (element) {
                    element.classList.remove('grad-transition'); // Supprimer la classe d'animation
                    void element.offsetWidth; // Forcer le recalcul du style pour réinitialiser l'animation
                    element.classList.add('grad-transition'); // Réappliquer la classe d'animation pour relancer l'animation
                }
            });
        }
        // Mettre à jour l'URL de l'image de l'écran précédent avec l'URL de l'image actuelle
        setPreviousScreenImage(currentScreenImage);
    }, [currentScreen, currentScreenImage, previousScreenImage]);


    return (
        <>
            <Title title={function_createSlug(categoryName)} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />

            {/*           <section>
                <WIPmessage />
            </section> */}


            <section>
                <div className="demo-strip isMobile">
                    Mode Démo <br />
                    <span>Rubrique “Mon compte” en cours de développement. </span>
                </div>
                <div className="big-window">
                    <div className="demo-strip">
                        Mode Démo <br />
                        <span>Rubrique “Mon compte” en cours de développement. </span>
                    </div>
                    <div className="big-window-col1">
                        {CurrentScreenComponent && <CurrentScreenComponent handleScreenChange={handleScreenChange} />}
                    </div>
                    <div className="big-window-col2">
                        <div className={`big-window-col2-gradientPrev ${previousScreenClass}`}></div>
                        <div className={`big-window-col2-gradientCurrent ${currentScreenClass} grad-transition`}></div>
                        <div className="big-window-col2-image grad-transition">
                            <img src={currentScreenImage} alt="" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default AccountPage;
