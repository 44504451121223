import React from 'react';
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';
// import WIPmessage from '../utils/WIPmessage';
import LicencesCardsList from '../components/LicencesCardsList';


function LastReleasesPage() {

    const categoryName = "Nouveautés"
    const slugCategory = "nouveautes"

    return (
        <>
            <Title title={slugCategory} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />

            <section>
                {/* <WIPmessage /> */}

                <LicencesCardsList categoryName={categoryName} />
            </section>

        </>
    );
};

export default LastReleasesPage;
