
export function function_createSlug(str) {

    const slug = str
        .normalize("NFD") // décompose les caractères accentués
        .replace(/[\u0300-\u036f]/g, "")    // supprime les caractères accentués
        .replace(/[\s]/g, "-") // remplace les espaces par des tirets
        .replace(/[^\w\s]/gi, "-") // remplace les caractères spéciaux par des tirets
        .replace(/--+/g, "-") // remplace les séquences de tirets par un seul tiret
        .replace(/-+$/g, "") // supprime les tirets en fin de chaîne
        .toLowerCase(); // convertit la chaîne de caractères en minuscules
    return slug;

}

