import React from 'react'
import { Link } from 'react-router-dom'

function Hero() {
    return (
        <>
            <div className='hero'>
                <div className="hero-pattern"></div>
                <div className="content">
                    <div className="baseline">
                        <div className="baseline-text">
                            <div className='baseline-text-line1'>Gérez votre collection</div>
                            <div className='baseline-text-line2'>de figurines Funko Pop!</div>
                        </div>
                        <Link to="/licences" className="button button_secondary">Voir toutes les licences</Link>
                    </div>

                    <div className="illustration"><img src="https://thomak.xyz/pop-collection/assets/images/header/visuel-hero.png" alt="" /></div>
                </div>
            </div>
        </>
    )
}

export default Hero