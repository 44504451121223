import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import Breadcrumb from '../components/Breadcrumb';
import CollectionLicencesCardsList from '../components/CollectionLicencesCardsList';
import { useParams } from "react-router-dom";
import config_json from '../utils/config_json';

function CollectionPage() {
    const [hasOnlyOneTrue, setHasOnlyOneTrue] = useState([]);

    const { licence: slugLicence } = useParams();


    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {
                const hasOnlyOneTrue = data.find(item => item.add_state === true);
                setHasOnlyOneTrue(hasOnlyOneTrue);
            })
            .catch(error => console.log(error));
    }, []);

    const categoryName = "Ma Collection"
    const slugCategory = "ma-collection"

    return (
        <>
            <Title title={slugCategory} />

            <Breadcrumb currentCategory={categoryName} slugCategory={slugCategory} />



            {/* message si Empty State */}

            {hasOnlyOneTrue ? (
                <section className='ma-collection-page'>
                    <CollectionLicencesCardsList slugLicence={slugLicence} />
                </section>
            ) : (
                <section>
                    <div className="big-window">
                        <div className="big-window-col1" style={{ alignItems: 'normal', padding: '70px' }}>
                            <div className="big-window-col1-content" style={{ justifyContent: "space-between", maxWidth: "530px" }}>
                                <div className="big-window-title" style={{ fontSize: "45px" }}>Votre collection est vide</div>

                                <div className="callout-collection">
                                    <div className="callout-collection-title">Comment ajouter des figurines <br />à votre collection ? </div>
                                    <div className="separateur-horizontal"></div>

                                    <div>Depuis la fiche de la figurine </div>
                                    <div className="row">
                                        <div className="image"><img src="https://thomak.xyz/pop-collection/assets/images/collection/mini-capture-product-page.jpg" alt="" /></div>
                                        <div className="col">
                                            <div className="line">
                                                <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-add-active-product-page.svg" alt="" />
                                                <div>Ajouter à votre collection</div>
                                            </div>
                                            <div className="line">
                                                <img src="https://thomak.xyz/pop-collection/assets/images/pictos/picto-fav-active-product-page.svg" alt="" />
                                                <div>Ajouter à votre liste de souhaits</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="big-window-col2 gradient1">
                            <div className="big-window-col2-image">
                                <img src="https://thomak.xyz/pop-collection/assets/images/collection/figure-collection-empty.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )

}
export default CollectionPage;