import React, { useState, useEffect, Suspense } from "react";
import FigurineCard from "./FigurineCard";
import FigurineCardDebug2 from "./FigurineCardDebug2";
import FigurineCardDebug3 from "./FigurineCardDebug3";
import { isDateGreaterThanToday } from '../utils/function_isDateGreaterThanToday';
import ScrollToTopButton from "../utils/ScrollToTopButton";
import config_json from '../utils/config_json';


// DEBUG  Fichier debug

function FigurineCardsListDebug(props) {
    const [filteredFigurines, setFilteredFigurines] = useState([]);
    const [imagesManquantes, setImagesManquantes] = useState([]);


    const [loadingData, setLoadingData] = useState(true);
    const [error, setError] = useState(false);



    // fonction de vérififcation des images (manquantes)
    const checkUnavailableImages = async (imageUrls) => {
        const unavailableImages = [];

        for (const imageUrl of imageUrls) {
            try {
                await new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = () => {
                        // L'image n'a pas pu être chargée, elle est considérée comme manquante
                        unavailableImages.push(imageUrl);
                        reject();
                    };
                    img.src = imageUrl;
                });
            } catch (error) {
                console.error(`Erreur lors de la vérification de l'image ${imageUrl} :`, error);
            }
        }
        return unavailableImages;
    };



    useEffect(() => {
        fetch(config_json.jsonFigurinesURL)
            .then(response => response.json())
            .then(data => {

                setFilteredFigurines(data);



                const imagesAvecInfos = data.map(item => ({
                    imageUrl: item.images.img_url1,
                    nom: item.nom,
                    licence: item.licence,
                    ref: item.ref
                }));


                // Vérifie les images indisponibles
                checkUnavailableImages(imagesAvecInfos)
                    .then(unavailableImages => {
                        console.log('Images indisponibles :', unavailableImages);
                        setImagesManquantes(unavailableImages)
                    })
                    .catch(error => {
                        console.error('Erreur lors de la vérification des images indisponibles :', error);
                    });
                // Crée un tableau contenant uniquement les URLs des images
                const imageUrls = imagesAvecInfos.map(image => image.imageUrl);

                // Vérifie les images indisponibles
                checkUnavailableImages(imageUrls)
                    .then(unavailableImageUrls => {
                        // Filtrer les objets images correspondant aux URLs indisponibles
                        const imagesManquantesAvecInfos = imagesAvecInfos.filter(image => unavailableImageUrls.includes(image.imageUrl));

                        // Met à jour le state avec les images manquantes
                        setImagesManquantes(imagesManquantesAvecInfos);
                    })
                    .catch(error => {
                        console.error('Erreur lors de la vérification des images indisponibles :', error);
                    });

                //-------------------------------------

                setLoadingData(false);
                setError(false);
            })
            .catch(error => {
                // Afficher l'erreur dans la console
                console.log(error);

                // Mettre à jour les variables d'état en cas d'erreur
                setLoadingData(false);
                setError(true);
            })

    }, [props.slugLicence, props.categoryName]);





    if (loadingData) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Chargement des figurines...</div >;
    }

    if (error) {
        return <div style={{ textAlign: "center", fontFamily: "Archivo", lineHeight: "1.5em" }}> Une erreur s'est produite lors du chargement des données. <br />
            Veuillez recharger la page.</div >;
    }
    return (
        <>

            <div>
                <h2>Images Manquantes</h2>
                <ul style={{ fontFamily: "courrier, sans-serif", marginBottom: "50px" }}>
                    {imagesManquantes.map((image, index) => (
                        <li key={index}>
                            <div>
                                <p>{image.licence} - {image.nom} (#{image.ref})</p>
                                <p>URL de l'image manquante: <em>{image.imageUrl}</em></p>
                                <p>---------------------------</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="cards-container card-grid-big">
                {/* Tri par ref puis par date */}
                {filteredFigurines.map((item) => (
                    <>
                        <Suspense fallback={
                            <div>
                                <img
                                    src="https://thomak.xyz/pop-collection/assets/images/figurineCard-placeholder.svg"
                                    alt=""
                                />
                            </div>
                        }>
                            <FigurineCard
                                key={item.id}
                                id={item.id}
                                id_funko={item.id_funko}
                                ref_funko={item.ref}

                                nom={item.nom}
                                nom_ed={item.nom_ed}
                                licence={item.licence}
                                couleur_licence={item.couleur_licence}

                                image={item.images.img_url1}
                                imageHover={item.images.img_url3}

                                editions={item.editions}
                                ed_spe1={item.editions ? item.editions.ed_spe1 : null}
                                ed_spe2={item.editions ? item.editions.ed_spe2 : null}
                                ed_spe3={item.editions ? item.editions.ed_spe3 : null}
                                variant_form={item.variant_form}

                                date_sortie={item.date_sortie_aff}

                                // debug
                                date_sortie_tri={item.date_sortie_tri}

                                fav_state={item.fav_state}
                                add_state={item.add_state}

                                slug_figurine={item.slug_figurine}
                                slug_licence={item.slug_licence}

                                future={isDateGreaterThanToday(item.date_sortie_tri)}
                                categoryName={props.categoryName}
                            />
                        </Suspense>



                        {/*  */}
                    </>
                ))}
            </div >

            <ScrollToTopButton />
        </>
    );
}

export default FigurineCardsListDebug;